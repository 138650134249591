import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MenuItemWrapper from './MenuItemStyle.js';


class MenuItem extends Component {

  render() {
    const {title, children, onClick, itemStyle, iconHeight, to} = this.props;
    const IconComponent = children;
    return (
        <MenuItemWrapper onClick={onClick} to={to} itemStyle={itemStyle}>
          <MenuItemWrapper.Icon iconHeight={iconHeight}>
            <IconComponent alt={title}  height="100%"/>
          </MenuItemWrapper.Icon>
          <MenuItemWrapper.Label>{title}</MenuItemWrapper.Label>
        </MenuItemWrapper>
    );
  }
}

MenuItem.propTypes = {
  to: PropTypes.string.isRequired,
  itemStyle: PropTypes.oneOf(['first', 'second', 'third']),
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  iconHeight: PropTypes.number,
  onClick: PropTypes.func
};

MenuItem.defaultProps = {
  description: '',
  itemStyle: 'first',
  iconHeight: null,
  onClick: () => {}
}

export default MenuItem;
