import React, { Component } from 'react';
import Container from 'styles/shared/Container.js';
import withScrollProgressBar from 'components/hocs/withScrollProgressBar/WithScrollProgressBar';
import scrollAtBottom from 'components/hocs/scrollAtBottom/ScrollAtBottom';
import {
  HeroContainer,
  HeroCover,
  ScreenWrapper,
  ProjectLogo,
  MainQuote,
  ResponsiveImage
  } from 'styles/shared/elementsStyles.js';


import heroBig from 'img/projects/fitwards/fitwards-hero.jpg';
import bottomHero from 'img/projects/fitwards/fitwards-bottom-hero.jpg';
import logo from 'img/projects/fitwards/fitwards-logo-color.svg';
import fitwardsMobiles from 'img/projects/fitwards/fitwards-mobile.jpg';
import fitwardsReward from 'img/projects/fitwards/fitwards-reward.jpg';

import fitwardsScreenmap from 'img/projects/fitwards/fitwards-screenmap.png';

class Fitwards extends Component {

  render() {
    return (
      <ScreenWrapper>
        <HeroContainer noPadding>
          <HeroCover bgImgBig={heroBig}/>
          <ProjectLogo logoHeight="120px">
            <img src={logo} alt="Fitwards" />
          </ProjectLogo>
          <span>Some Pain, Sure Gain</span>
        </HeroContainer>
        <Container>
          <div>
            <p>
              En nuestra grupo cada año se celebra un <strong>pitch-a-thon</strong>, un concurso de ideas para generar ideas y posibles futuros proyectos.
            </p>
            <p>Bueno, pues en la primera edición hicimos un equipo creamos una idea, la presentamos y ¡ganamos!</p>
            <p> Nuestro proyecto era <strong>Fitwards</strong></p>
            <MainQuote>
              <p>La idea es una app de running que te propone retos. Si los completas desbloqueas una scratch card con la que puedes ganar premios relacionados con el running.</p>
            </MainQuote>
            <p>Teniamos 2 días para pensar en el concepto, preparar una presentación y hacer un mini bussiness plan para luego hacer el pitch.</p>
          </div>

          <h2>¿Cómo funcionaba la App?</h2>
          <p>
            Se trataba de un producto de lotería (Scracth Cards) recubierto de una app de retos con recompensas. Estaba orientado a un publico deportista centrado en el running.
          </p>
          <ul>
          <li>
            La app presentaba un listado de retos de running que se podían comprar por 1€, Correr 5Km, Correr 10Km....
          </li>
          <li>
            El usuario completaba uno de los retos que había comprado. La app chequeaba que realmente habías completado el reto y desbloqueaba la recompensa.
          </li>
          <li>
            El usuario "rascaba" su premio y recibía su premio, que estaba relacionado con el running, unas zapatillas, ropa deportiva, etc...
          </li>
          </ul>

          <ResponsiveImage src={fitwardsMobiles} />
        </Container>
        <Container>
          <p>
            La idea y la presentación gustó mucho dentro del grupo, la verdad es que hicimos un pitch bastante bueno.
          </p>
          <ResponsiveImage src={fitwardsReward} />
        </Container>
        <Container>
        <h2>Un pequeño prototipo</h2>
        <p>
          La idea del equipo era llevar a cabo el desarrollo de proyecto y poder ver su viabilidad. Para ello decidimos crear un pequeño MVP lanzarlo y ver como respondía el público.
        </p>
        <p>
          Creamos una APP en <strong>React Native</strong> que se conectaba con otra APP de running, Strava en este caso, para poder obetener los datos del usuario y poder ver si había conseguido el reto.
        </p>
        <p>
        Por temas de carga de trabajo no pudimos continuar con el prototipo y lo dejamos en stand-by. 
        <strong>¡Pero aprendimos un montón por el camino!</strong>
        </p>
          <ResponsiveImage src={fitwardsScreenmap} />
        </Container>


        <HeroContainer noPadding>
          <HeroCover bgImgBig={bottomHero}/>
        </HeroContainer>
      </ScreenWrapper>
    );
  }
}

export default scrollAtBottom(withScrollProgressBar(Fitwards));
