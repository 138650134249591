import styled, {keyframes} from 'styled-components';
import mapIcon from 'img/map-indicator.svg';

const icon = {
  width: 40,
  height: 51
};
function particleAnimation (props) {
  return keyframes`
    from {
        left: -100px;
    }
    to {
        left: calc( 100% + 100px );
    }`;
}


function pulse (props) {
  return keyframes`
    from,
    25%,
    55%,
    75%,
    to {
      animation-timing-function: ease;
    }

    from {
      opacity: 0;
      transform: scale(0.95) translate3d(0, 10px, 0);
    }

    25% {
      opacity: 1;
      transform: scale(1.05) translate3d(0, -20px, 0);
    }

    50% {
      transform: scale(0.95) translate3d(0, 10px, 0);
    }

    75% {
      transform: scale(1.05) translate3d(0, -5px, 0);
    }

    to {
      transform: scale(1) translate3d(0, 0, 0);
      opacity: 1;
    }
  `;
}

const MapIndicator = styled.div`
  color: #fff;
  position: absolute;
  right: 20%;
  top: 25%;
  z-index: 100;
  animation: ${props => pulse} .5s  2s forwards;
  opacity: 0;
  transform: translate3d(0, 10px, 0);
  font-size: 0.7em;
  &:after{
    position: absolute;
    content: "";
    bottom: -5px;
    left: -${(icon.width + 10)}px;
    background: url(${mapIcon}) no-repeat center;
    background-size: contain;
    width: ${icon.width}px;
    height: ${icon.height}px;
  }
`;

const ShootingStar = styled.div`
  position: absolute;
  left:0px;
  top: ${props => props.initTop}%;
  width:1px;
  height:1px;
  background-color:white;
  animation-name: ${particleAnimation};
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration:${props => props.duration}s;
  animation-delay:${props => props.delay}s;
  z-index: 100;
  &::before{
    position:absolute;
    display:block;
    content:"";
    width:100px;
    right:1px;
    top:0px;
    height:1px;
    background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(255,255,255,0.4) 100%);
  }
`;


const ContactFormWrapper = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colorsRSA.thirdDark};
  display: flex;
  align-items: center;
  justify-content: center;

`;
const FormContainer = styled.div`
  position: relative;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: ${props => props.theme.widthSiteWrapper};
  padding: ${props => props.theme.xxLargePadding} ${props => props.theme.largePadding};
  z-index: 1000;
  min-height: 710px;

  h2 {
    text-align: center;
    color: #fff;
    font: normal 300 1.4em ${props => props.theme.fontFamilyText};
  }
`;

const EmailSentOk = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  text-align: center;
  font-size: 1.4em;
  svg {
    width: 90px;
    margin-bottom: ${props => props.theme.xLargeMargin};

    path {
      fill: #fff;
    }
  }
`;
export {
  MapIndicator,
  ShootingStar,
  ContactFormWrapper,
  FormContainer,
  EmailSentOk
}
