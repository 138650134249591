import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Field, TextAreaFieldWrapper} from './TextAreaFieldStyle.js';



class TextAreaField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: (props.locked && props.active) || false,
      value: props.value || ''
    }
    this.onChange = this.onChange.bind(this);
  }
  onChange(event) {
    const value = event.target.value;
    this.setState({ value, error: '' });
    return this.props.onChange(event);
  }
  render() {
    const { active, value } = this.state;
    const { id, locked, name, error, label } = this.props;

    const isActive = locked ? active : active || value;
    const isLocked = locked && !active;

    return (
      <Field
        isActive = {isActive}
        isLocked = {isLocked}
        error = {error}
      >
        <TextAreaFieldWrapper
            id={id}
            name={name}
            value={value}
            placeholder={label}
            onChange={this.onChange}
            onFocus={() => !locked && this.setState({ active: true })}
            onBlur={() => !locked && this.setState({ active: false })}
        />

      </Field>
    );
  }



}

TextAreaField.propTypes = {
    id: PropTypes.string.isRequired,
    locked: PropTypes.bool,
    active: PropTypes.bool,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    error: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
 };
TextAreaField.defaultProps = {
    locked: false,
    active: false,
    value: '',
    error: '',
    label: '',
    onChange: () => '',
 };


export default TextAreaField;
