import styled from 'styled-components';
import { device } from 'styles/mediaQueries.js';
import videoBg from 'img/projects/gameBoyBg.png';


const VideoGameBoy = styled.div`
  width: 360px;
  height: 544px;
  background: url(${videoBg}) no-repeat center;
  background-size: contain;
  position: absolute;
  right: -125px;
  top: 160px;
  transform: rotate(-12deg) scale(.7);

  @media ${device.gameBoyBigDesktop} {
    transform: rotate(-12deg) scale(.8);
    right: -25px;
  }

  video {
    margin: 78px 93px;
    width: 50%;
  }
`;

export { VideoGameBoy };
