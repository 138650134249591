import React, { Component } from 'react';
import withScrollProgressBar from 'components/hocs/withScrollProgressBar/WithScrollProgressBar';
import scrollAtBottom from 'components/hocs/scrollAtBottom/ScrollAtBottom';
import { ViewProjectButton } from 'styles/shared/Buttons.js';
import Container from 'styles/shared/Container.js';
import {
  HeroContainer,
  Hero,
  HeroCover,
  ScreenWrapper,
  ProjectLogo,
  TechContent,
  TechContentRow,
  ScreenshotWrapper,
  MainQuote,
  ResponsiveImage} from 'styles/shared/elementsStyles.js';
import {
  EquiaIcons,
  EquiaIcon
} from './EquiaStyle.js';
import hero from 'img/projects/equia/equia-hero.jpg';
import bottomHero from 'img/projects/equia/equia-bottom-hero.jpg';
import equiaCard from 'img/projects/equia/equia_business_card.jpg';
import logo from 'img/projects/equia/equia-logo-color.svg';
import icon1 from 'img/projects/equia/equia-icon1.png';
import icon2 from 'img/projects/equia/equia-icon4.png';
import icon3 from 'img/projects/equia/equia-icon2.png';
import icon4 from 'img/projects/equia/equia-icon3.png';
import equiaNavigation from 'img/projects/equia/equia-mobiles.jpg';
import screenshot1 from 'img/projects/equia/equia-desktop-screenshot.jpg';



class Equia extends Component {

  render() {
    return (
      <ScreenWrapper withTopPadding>
        <HeroContainer >
          <Hero src={hero} alt="Asesoria Equia" />
          <ProjectLogo logoHeight="100px">
            <img src={logo} alt="Asesoria Equia"  />
          </ProjectLogo>
          <span>Asesoría para PYMES</span>
          <ViewProjectButton buttonColor="#5d7c00" href="http://www.asesoria-equia.com/" target="_blank" rel="noopener noreferrer" >Visitar Projecto</ViewProjectButton>
        </HeroContainer>
        <Container>
          <div>
            <p>
              Otro pequeño proyecto, la creación de una web para una asesoría de empresas, y si otro de mis proyectos era para mi suegro, ¡este es para mi cuñado! :-)
            </p>
            <MainQuote>
              <p>La idea es presentar los servicios de la asesoría de forma clara y sencilla y que muestre un caracter de profesionalidad y seriedad.</p>
            </MainQuote>
          </div>
        </Container>
        <TechContentRow>
          <TechContent>
          <ul>
            <li>HTML</li>
            <li>CSS</li>
            <li>JavaScript</li>
            <li>Foundation</li>
            <li>SASS</li>
          </ul>
          </TechContent>
        </TechContentRow>
        <Container>
          <div>
            <h2>Creando la web y la imagen corporativa</h2>
            <p>
              No sólo me ocupe de la implementación de la web, también me encargue de crear la imagen corporativa con su logo y luego un diseño web acorde.
            </p>
            <ResponsiveImage src={equiaCard} alt="Brand" />
            <p>
              Cada area de servicios se representa con un logo y un color dando robustez al diseño y a las distintas secciones.
            </p>
            <EquiaIcons>
              <EquiaIcon area="laboral">
                <img src={icon1} alt="Asesoría Laboral"/>
                <span>Asesoría Laboral</span>
              </EquiaIcon>
              <EquiaIcon area="fiscal">
                <img src={icon2} alt="Asesoría Fiscal"/>
                <span>Asesoría Fiscal</span>
              </EquiaIcon>
              <EquiaIcon area="contable">
                <img src={icon3} alt="Asesoría Contable"/>
                <span>Asesoría Contable</span>
              </EquiaIcon>
              <EquiaIcon area="juridico">
                <img src={icon4} alt="Asesoría Jurídica"/>
                <span>Asesoría Jurídica</span>
              </EquiaIcon>
            </EquiaIcons>

            </div>
        </Container>
        <ScreenshotWrapper withBgColor>
          <div><img src={screenshot1} alt="Area laboral en Asesoría Equia" /></div>
        </ScreenshotWrapper>

        <Container>
          <div>
          <p>
            El resultado fue una página sencilla pero con un diseño que funciona y cumple con las necesidades de una pequeña empresa.
          </p>
          <ResponsiveImage src={equiaNavigation} alt="Mobile Navigation" />
  
          </div>
        </Container>
        <HeroContainer noPadding>
          <HeroCover bgImgBig={bottomHero}/>
        </HeroContainer>
      </ScreenWrapper>
    );
  }
}

export default scrollAtBottom(withScrollProgressBar(Equia));
