import styled from 'styled-components';
import { device } from 'styles/mediaQueries.js';

const IconsProducts = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin:  ${props => props.theme.xxLargeMargin} auto;
  @media ${device.mobile} {
    justify-content: space-around;
  }
`;

const Icon = styled.div`
  &:after {
    font: 400 4em ventura24;
    @media ${device.desktop} {
      font-size: 8em;
    }
  }
`;

const Euromillones = styled(Icon)`
  &:after {
    content: "\\e600";
  }
`;

const Primitiva = styled(Icon)`
  &:after {
    content: "\\e605";
  }
`;

const Bonoloto = styled(Icon)`
  &:after {
    content: "\\e607";
  }
`;

const ElGordo = styled(Icon)`
  &:after {
    content: "\\e606";
  }
`;
const Quiniela = styled(Icon)`
  &:after {
    content: "\\e608";
  }
`;

const LoteriaNacional = styled(Icon)`
  &:after {
    content: "\\e609";
  }
`;

export { IconsProducts, Euromillones, Primitiva, Bonoloto, ElGordo, Quiniela, LoteriaNacional };
