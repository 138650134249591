import React, { Component } from 'react';
import Container from 'styles/shared/Container.js';
import { ViewProjectButton } from 'styles/shared/Buttons.js';
import withScrollProgressBar from 'components/hocs/withScrollProgressBar/WithScrollProgressBar';
import scrollAtBottom from 'components/hocs/scrollAtBottom/ScrollAtBottom';
import VideoPlayerDesktop from 'components/videoPlayerDesktop/VideoPlayerDesktop'
import {
  HeroContainer,
  HeroCover,
  ScreenWrapper,
  ProjectLogo,
  TechContent,
  TechContentRow,
  TechDescription,
  TechSeparator,
  ScreenshotWrapper,
  MainQuote,
  ResponsiveImage
  } from 'styles/shared/elementsStyles.js';


import heroBig from 'img/projects/tga/tga-heroBig.png';
import logo from 'img/projects/tga/tga-logo-color.svg';
import desktopVideo from 'img/projects/tga/tga-desktop.mp4';
import esculturasVideo from 'img/projects/tga/esculturas.mp4';
import tgaNavigation from 'img/projects/tga/tga-navigation.jpg';
import screenshot1 from 'img/projects/tga/tga-desktop1.jpg';


class Tga extends Component {

  render() {
    return (
      <ScreenWrapper>
        <HeroContainer noPadding>
          <HeroCover bgImgBig={heroBig}/>
          <ProjectLogo logoHeight="200px">
            <img src={logo} alt="Tomas García Asensio" />
          </ProjectLogo>
          <span>De los cuadros a las pantallas</span>
          <ViewProjectButton buttonColor="#7e52a8" href="http://www.tomasgarciaasensio.com/" target="_blank" rel="noopener noreferrer" >Visitar Projecto</ViewProjectButton>
        </HeroContainer>
        <Container>
          <div>
            <p>
              Recibí el encargo de realizar la página de Tomás García Asensio, un gran pintor constructivista, y todo hay que decirlo, <strong>¡se trata de mi suegro!</strong>, así que no podía negarme, la familia es la familia :-)
            </p>
            <MainQuote>
              <p>La obra de este pintor esta muy bien categorizada en distintas colecciones todas englobadas en una misma teoría de color</p>
              <p>El reto era convertir cada una de estás colecciones en una sección de modo que la home fuese una especie de exposición de todas las colecciones.</p>
            </MainQuote>
          </div>
        </Container>
        <TechContentRow>
          <TechContent>
          <ul>
            <li>HTML</li>
            <li>CSS</li>
            <li>JavaScript</li>
            <li>AngularJs</li>
            <li>Foundation</li>
            <li>SASS</li>
          </ul>
          </TechContent>
          <TechSeparator />
          <TechDescription>
            <p>Al tratarse de un pequeño proyecto personal no había necesidad de crear ningún backend. Utilicé Foundation un framework de frontend que me hizo la vida más fácil para la parte de presentación, css grid, botones, navegación, etc...</p>
            <p>
              Para páginas que repetian una misma estructura como el interior cada galaría utilice AngularJs
            </p>
          </TechDescription>
        </TechContentRow>
        <TechContentRow>

          <VideoPlayerDesktop video={desktopVideo} />

        </TechContentRow>
        <Container>

                      <h2>Está mal que lo diga pero...</h2>
                      <p>
                        Lo cierto es que desde que lanzamos la web, la actividad del pintor (mi suegro) aumentó.
                      </p>
                      <p>
                      Aumentaron los contactos, la presencia online mejoró, surgieron exposiciones y también <strong>¡las ventas de cuadros!</strong>.
                      </p>
              <h2>El color protagonista de la página</h2>
              <p>
                Ya que el tema principal de este pintor es el color, quería que la página fuese muy colorida y muy clara.
              </p>
            <ResponsiveImage src={tgaNavigation} />
              <p>
                Cada una de las galerías tiene un color distinto, adaptándose todos los elementos de la página a dicho color, títulos, cabecera, navegación, footer...
              </p>
        </Container>
        <ScreenshotWrapper withBgColor>
          <div><img src={screenshot1} alt="Galería de Tomás García Asensio" /></div>
        </ScreenshotWrapper>

        <Container>
          <h2>Esculturas</h2>
          <p>
            Un reto que quería destacar era como prensentar las esculturas de este pintor. Son unas esculturas planas donde cada uno de los lados está pintado con distintos colores acordes con su teoría de color.
          </p>
          <p>
            La solución fue crear una imagen para cada uno de los lados de la escultura y visualizarlas como una sóla imagen. Al pasar el ratón por encima (o hacer tap en el movil) se daba la vuelta mostrando la otra cara de la escultura.
          </p>
        </Container>
        <Container>
          <VideoPlayerDesktop video={esculturasVideo} />
        </Container>
        <Container>
          <p>Quedó muy chulo, eso si, fue todo un peñazo recortar todas las imágenes de las esculturas. :-)</p>

        </Container>
      </ScreenWrapper>
    );
  }
}

export default scrollAtBottom(withScrollProgressBar(Tga));
