import styled from 'styled-components';
import { device } from 'styles/mediaQueries.js';


const UnnoVars = {
  mainColor: '#078be0',
  borderColor: '#82ccfb',
  iconSizeSmall: '50px',
  iconSizeBig: '80px'
}
const UnnoIcons = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const UnnoIcon = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.mediumPadding} 0;
  margin: ${props => props.theme.smallMargin}
  border-bottom: 2px solid ${UnnoVars.mainColor};

  & > span {
    color: ${UnnoVars.mainColor};
    text-align: center;
    text-transform: uppercase;
    font-size: 0.7em;
  }

  & > svg {
      height: ${UnnoVars.iconSizeSmall};
      path {
        fill: ${UnnoVars.mainColor};
      }

      @media ${device.mobileL} {
        height: ${UnnoVars.iconSizeBig};
      }
    }
  }

`;


export { UnnoIcons, UnnoIcon };
