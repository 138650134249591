import styled from 'styled-components';
import mainLogo from 'img/logo-rsa.svg';
import { device } from 'styles/mediaQueries.js';

const NavContainer = styled.div`
  width: ${props => props.theme.menuWidth};
  position: fixed;
  overflow-y: auto;
  height: 100%;
  background: #fff;
  border-right: 1px solid ${props => props.theme.separatorColor};
  z-index: 10000;
  transition: transform 0.3s ease;
  transform: translateX(-${props => props.theme.menuWidth});
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  ${props => props.isMenuOpened && `
    transform: translateX(0);
  `}
  @media ${device.tablet} {
    transform: translateX(0);
  }

  & > * {
    width: 100%;
  }
`;
const MenuCover = styled.div`
  background: ${props => props.theme.menuCoverColor};
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  display: none;
  @media ${device.tablet} {
    display: none;
  }
  ${props => props.isMenuOpened && `
    display: block;
  `}
`;

const MenuContainer = styled.nav`

`;
const LogoMenuName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font: 400 .8em ${props => props.theme.fontFamilyTitleAlternative};
  color: ${props => props.theme.baseTextColor};
  text-transform: uppercase;
  letter-spacing: 6px;
  span:first-child {
    letter-spacing: 8.5px;
  }
`;
const LogoMenu = styled.a`
  background: url(${mainLogo}) no-repeat center top;
  background-size: contain;
  width: 140px;
  margin: ${props => props.theme.xLargeMargin} auto ${props => props.theme.xLargeMargin} auto;
  padding-top: 60px;
  display: block;

`;

const MenuFooter = styled.div`
  margin-top: auto;
  padding: ${props => props.theme.largePadding};
  text-align: center;
  color: #999;
  font: 400 .8em ${props => props.theme.fontFamilyTitle};
  text-transform: lowercase;
`;

export {MenuCover, MenuContainer, NavContainer, LogoMenu, LogoMenuName, MenuFooter};
