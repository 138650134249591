import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import PipeContainer from './PipeGeneratorStyle.js';

const MIN_SIZE = 4;
const MAX_SIZE = 5.5;
const H_MIN_DURATION = 2;
const H_MAX_DURATION = 5;
const H_MIN_LENGTH = 30;
const H_MAX_LENGTH = 100;
const V_MIN_DURATION = 3;
const V_MAX_DURATION = 12;
const V_MIN_LENGTH = 100;
const V_MAX_LENGTH = 250;

const MIN_RIGHT_R_AREA = 5;
const MAX_RIGHT_R_AREA = 25;
const MIN_RIGHT_L_AREA = 75;
const MAX_RIGHT_L_AREA = 95;

const MAX_STEPS = 12;

const CIRCLE_RATIO = 3;
const INNER_CIRCLE_RATIO = .65;

class PipeGenerator extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pipes: this.generateRandomPipes(props)
    }
  }

  rand(min, max) {
    if (min==null && max==null)
      return 0;

      if (max == null) {
        max = min;
        min = 0;
      }
      return min + Math.floor(Math.random() * (max - min + 1));
  };

  randFloat(min, max) {
    return (Math.random() * (max - min) + min)
  }

  getRandomColor() {
    /*var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;*/
    const availableColors = [
      this.props.theme.colorsRSA.first,
      this.props.theme.colorsRSA.second,
      this.props.theme.colorsRSA.third,
      "#999",
      "#AAA",
      "#CCC"
    ];
    return availableColors[Math.floor(Math.random()*availableColors.length)]
  }


  getRandomDirection() {
    switch (this.rand(1, 3)) {
      case 1:
        return 'top';
      case 2:
        return 'left';
      case 3:
        return 'right';
      default:

    }
  }

  getNextPipe(pipe) {
    let nextPipe = {...pipe};

    nextPipe.delay = pipe.duration + pipe.delay;
    nextPipe.zIndex = pipe.zIndex-1;

    if (pipe.direction === 'left' || pipe.direction === 'right') {
      nextPipe.duration = this.randFloat(V_MIN_DURATION, V_MAX_DURATION);
      nextPipe.length = this.randFloat(V_MIN_LENGTH, V_MAX_LENGTH);
      nextPipe.bottomPosition = pipe.bottomPosition + pipe.width / 2;
      nextPipe.direction = 'top';
      if (pipe.direction === 'left') {
        nextPipe.rightOffset = pipe.rightOffset + pipe.length + pipe.width / 2;
      }
      if (pipe.direction === 'right') {
        nextPipe.rightOffset = pipe.rightOffset - pipe.length + pipe.width / 2;
      }
    } else {
      nextPipe.duration = this.randFloat(H_MIN_DURATION, H_MAX_DURATION);
      nextPipe.length = this.randFloat(H_MIN_LENGTH, H_MAX_LENGTH);
      nextPipe.bottomPosition = pipe.bottomPosition + pipe.length;
      nextPipe.direction = this.getRandomDirection();
      if (nextPipe.direction === 'left') {
        nextPipe.bottomPosition-= pipe.width / 2;
      }
      if (nextPipe.direction === 'right') {
        nextPipe.bottomPosition+= pipe.width / 2;
      }
    }
    return nextPipe;
  }
  
  getInitialRightPosition(area) {
    return (area === 'right') ?
      this.randFloat(MIN_RIGHT_R_AREA, MAX_RIGHT_R_AREA) :
      this.randFloat(MIN_RIGHT_L_AREA, MAX_RIGHT_L_AREA);
  }

  generateRandomPipes(props) {
    let initialPipe = {
      width: this.randFloat(MIN_SIZE, MAX_SIZE),
      rightPosition: this.getInitialRightPosition(props.area),
      rightOffset: 0,
      length: this.randFloat(V_MIN_LENGTH, V_MAX_LENGTH),
      bottomPosition: 0,
      delay: this.randFloat(0, 2),
      duration: this.randFloat(V_MIN_DURATION, V_MAX_DURATION),
      direction: 'top',
      color: this.getRandomColor(),
      circleRatio: CIRCLE_RATIO,
      innerCircleRatio: INNER_CIRCLE_RATIO,
      zIndex: MAX_STEPS,
    }

    let pipes = [];
    let nextPipe =initialPipe;

    for (let i=0; i<MAX_STEPS; i++) {
      pipes.push(<PipeContainer {...nextPipe} key={i} />);
      nextPipe = this.getNextPipe(nextPipe);
    }

    return pipes;

  }
  render() {
    return (
      <div>
        {this.state.pipes}
      </div>
    );
  }
}

PipeGenerator.propTypes = {
  id: PropTypes.string,
  area: PropTypes.oneOf(['left','right']),
};

PipeGenerator.defaultProps = {
  id: 'pipe_genator_id',
  area: 'right'
}


export default withTheme(PipeGenerator);
