import styled from 'styled-components';
import { device } from 'styles/mediaQueries.js';

const ExperienceRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.largeMargin};
  @media ${device.desktop} {
    flex-direction: row;
    align-items: flex-start;
  }
  p:first-child {
    margin-top: 0;
  }
`;

const Dates = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.9em;
  margin-bottom: ${props => props.theme.largeMargin};
  span {
    font-weight: bold;
  }
`;
const Description = styled.div`
  font-size: 0.9em;
`;

const TechSkills = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.largeMargin};
  flex-wrap: wrap;
`;
const SkillGroup = styled.div`
  font-size: 0.9em;
  flex-basis: 100%;
  flex: 1;
  display: block;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  padding: ${props => props.theme.mediumPadding};
  min-height: 350px;
  margin: ${props => props.theme.mediumMargin};
  div {
    font-weight: bold;
    text-align: center;
  }
  ul {
    padding-left: ${props => props.theme.largePadding};
  }

`;


export {
  ExperienceRow,
  Dates,
  Description,
  TechSkills,
  SkillGroup
}
