import React, { Component } from 'react';
import Container from 'styles/shared/Container.js';
import withScrollProgressBar from 'components/hocs/withScrollProgressBar/WithScrollProgressBar';
import scrollAtBottom from 'components/hocs/scrollAtBottom/ScrollAtBottom';
import {
  HeroContainer,
  HeroCover,
  ScreenWrapper,
  ProjectLogo,
  ScreenshotWrapper,
  MainQuote,
  Columns,
  Column
  } from 'styles/shared/elementsStyles.js';

  import {
    Combination
  } from './LottotweeStyle.js';

import heroBig from 'img/projects/lottotwee/lottotwee-hero.jpg';

import logo from 'img/projects/lottotwee/logo-lottotwee-color.svg';
import raffldCombination from 'img/projects/lottotwee/lottotwee-combination.jpg';
import idea0 from 'img/projects/lottotwee/idea0.jpg';
import idea1 from 'img/projects/lottotwee/idea1.jpg';
import idea2 from 'img/projects/lottotwee/idea2.jpg';


class Lottotwee extends Component {

  render() {
    return (
      <ScreenWrapper>
        <HeroContainer noPadding>
          <HeroCover bgImgBig={heroBig}/>
          <ProjectLogo logoHeight="120px">
            <img src={logo} alt="Lottotwee" />
          </ProjectLogo>
          <span>Buscar pareja + Lotería = lottotwee</span>
        </HeroContainer>
        <Container>
          <div>
            <p>
              Bueno, está es una idea que tenía preparada para una fase de product discovery pero que nunca llegué a presentar.
            </p>
            <MainQuote>
              <p>La idea es básandose en el funcionamiento de Tinder hacer un producto similar pero donde haya un juego de lotería que se pueda jugar en pareja</p>
            </MainQuote>
          </div>
        </Container>
        <Container>
          <Columns>
            <Column>
              <h2>¿Cómo funcionaría?</h2>
              <p>
                Supongamos que estamos haciendo nuestro perfil y tenemos que describirnos, pues utilizariamos una combinación de lotería para ello.
                En vez de números utilizaríamos simbólos que representan nuestros gustos (tipos de comida, aficiones, deportes, música...). De está forma con nuestra combinación nos estarímos describiendo.
              </p>

              <p>
                A la hora de buscar una pareja, nos podriamos fijar en su combinación para ver si sus gustos son compatibles con los nuestros. Si el perfil nos gusta intentamos conectar para ver si surge el match.
              </p>

            </Column>
            <Column>
              <Combination src={raffldCombination} />
            </Column>
            </Columns>
        </Container>

        <Container>
          <h2>Después del match</h2>
          <p>
          Pues una vez hay match, la pareja pasaría a jugar al producto de lotería de forma combinada, es decir, que tendrian 2 combinaciones con las que poder llevarse un premio. Si se llevan un premio se repartiría entre los dos teniendo la excusa perfecta para tener una cita :-)
          </p>

        </Container>
        <ScreenshotWrapper withBgColor>
          <div><img src={idea0} alt="Perfil 1" /></div>
          <div><img src={idea1} alt="Perfil 2" /></div>
          <div><img src={idea2} alt="Jugando juntos después del match" /></div>
        </ScreenshotWrapper>
        <Container>
          <div>
          <h2>Todo se queda en el tintero</h2>
          <p>
            Seguramente sería una idea peregrina y nunca llegaría a funcionar, pero todo el proceso de generar las ideas e intentar plasmarlas en diseños es bastante divertido.
          </p>
          </div>
        </Container>

      </ScreenWrapper>
    );
  }
}

export default scrollAtBottom(withScrollProgressBar(Lottotwee));
