import styled, {css} from 'styled-components';
import mainLogo from 'img/logo-rsa.svg';
import leftBg from 'img/left-bg.svg';
import rightBg from 'img/right-bg.svg';
import { device } from 'styles/mediaQueries.js';

const MainContainer = styled.main`
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  ${props => props.withMenu && css`
    @media ${device.tablet} {
      width: calc(100% - ${props => props.theme.menuWidth});
    }
  `}

`;


const OuterContainer = styled.div`

`;

const PipeContainer = styled.div`
  height: 100%;
  background: none;
  @media ${device.desktopMainBg} {
    background: url(${leftBg}) no-repeat 5% 100% fixed, url(${rightBg}) no-repeat 95% 100% fixed;
  }
`;



const ContentWrapper = styled.div`
  position: relative;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: ${props => props.theme.widthSiteWrapper};
  padding: ${props => props.theme.xLargePadding} ${props => props.theme.largePadding};
  z-index: 1000;
  background-color: #fff;
  @media ${device.desktop} {
    max-width: ${props => props.theme.widthSiteWrapperLarge};
  }
`;

const ProjectsContentWrapper = styled(ContentWrapper)`
  @media ${device.desktop} {
    max-width: ${props => props.theme.widthSiteWrapper};
  }
`

const MainLogo = styled.a`
  background: url(${mainLogo}) no-repeat center;
  background-size: contain;
  width: 250px;
  height: 81px;
  display: block;
  margin: 0 auto ${props => props.theme.xLargeMargin} auto;
`;





export { MainContainer, OuterContainer, PipeContainer, ContentWrapper, ProjectsContentWrapper,  MainLogo };
