import styled, { css } from 'styled-components';
import { device, fromWidth } from 'styles/mediaQueries.js';
/*
const Container = styled.div`
    padding-left: ${props => {
        if (props.full) return 0
        return 'calc((100vw - 960px) / 2)'
    }};
    padding-right: ${props => {
        if (props.full) return 0
        return 'calc((100vw - 960px) / 2)'
    }};
    padding-top: ${props => {
        if (props.fullVertical) return 0
        if (props.small) return '15px'
        return '25px'
    }};
    padding-bottom: ${props => {
        if (props.fullVertical) return 0
        if (props.small) return '15px'
        return '25px'
    }};
`*/

const Container = styled.div`
  position: relative;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: ${props => props.theme.widthSiteWrapper};
  padding: ${props => props.theme.largePadding} ${props => props.theme.largePadding};
  z-index: 1000;
  background-color: ${props => props.transparent ? 'transparent' : '#fff'};
  @media ${device.desktop} {
    padding: ${props => props.theme.xLargePadding} ${props => props.theme.largePadding};
    max-width: ${props => props.theme.widthSiteWrapperLarge};
  }

  ${props => {
       return (
           props.projects &&
           css`

             @media ${fromWidth(1224)} {
               max-width: 1024px
             }
             @media ${fromWidth(1300)} {
               max-width: 900px
             }
             @media ${fromWidth(2100)} {
               max-width: 1100px
             }

           `
       )
   }}


`;
export default Container
