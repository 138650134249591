import React, { Component } from 'react';
import Container from 'styles/shared/Container.js';
import scrollAtBottom from 'components/hocs/scrollAtBottom/ScrollAtBottom';
import withScrollProgressBar from 'components/hocs/withScrollProgressBar/WithScrollProgressBar';
import {
  HeroContainer,
  HeroCover,
  ScreenWrapper,
  ProjectLogo,
  TechContent,
  TechContentRow,
  TechDescription,
  TechSeparator,
  MainQuote,
  ResponsiveImage
  } from 'styles/shared/elementsStyles.js';



import hero from 'img/projects/rsa/rsa-hero.jpg';
import heroMobile from 'img/projects/rsa/rsa-hero-mobile.jpg';
import logo from 'img/logo-rsa.svg';
import reactStyledImg from 'img/projects/rsa/rsa-react-styled.png';
import rsaDesktop from 'img/projects/rsa/rsa-desktop.png';
import rsaMobileNavigation from 'img/projects/rsa/rsa-mobile-navigation.jpg';



class Rsa extends Component {

  render() {
    return (
      <ScreenWrapper>
      <HeroContainer noPadding>
        <HeroCover bgImgBig={hero} bgImgSmall={heroMobile} />
        <ProjectLogo logoHeight="120px">
          <img src={logo} alt="Raffld" />
        </ProjectLogo>
        <span>Efectivamente, esta misma página</span>
      </HeroContainer>
        <Container>
          <div>
            <p>
              Pues después de quedarme en paro pensé, pues tendré que tener algo que enseñar en una entrevista de trabajo, y aquí está mi página. Un proyecto que me hacía ilusión pero que nunca tenía tenía tiempo para hacer.
            </p>
            <MainQuote>
              <p>Si estás leyendo esto puede que estes buscando un desarrollador frontend y yo puedo estar buscando trabajo, ¡hey! ese es el objetivo de esta página :-)</p>
            </MainQuote>
            <p>
              Mi madre dice que la página ha quedado "muy bonita".
            </p>
          </div>
          <h2>React + Styled Components</h2>
          <p>
            Depués de utilizar React en mis últimos proyectos decidí que mi página fuera una single page application en React.
          </p>
          <p>
            Con <strong>Styled Components</strong> era más esceptico, yo me sentía cómodo usando SASS y CSS Módules, pero decidí probarlo y la verdad es que fue un acierto, ya que tienes todas las ventajas de usar CSS-in-JS y luego no echas de menos las bondades que te ofrece SASS.
          </p>
          <ResponsiveImage src={reactStyledImg} />
        </Container>

        <TechContentRow>
          <TechContent>
          <ul>
            <li>JavaScript</li>
            <li>React</li>
            <li>Styled Components</li>
          </ul>
          </TechContent>
          <TechSeparator />
          <TechDescription>
            Utilicé el starter kit <strong>Create React App</strong> para ahorrarme todo el wiring y set up del proyecto.
          </TechDescription>
        </TechContentRow>
        <Container>
          <h2>Diseño y UX</h2>
          <p>
            Aprovechando mi experiencia previa en otros proyectos en los que he tenido que diseñar y hacer temás de usabilidad me puse manos a la obra y decidí crear un diseño desde cero y una usabilidad sencilla para el tipo de página que buscaba.
          </p>
          <ResponsiveImage src={rsaDesktop} />
        </Container>
        <Container>
              <p>
                He utilizado sólo tres colores para toda la web, uno por cada sección.
              </p>
              <p>
                He intentado añadir pequeños detalles que ayuden a la navegación por el site, como la barra de progreso de scroll, o los botones de volver a atrás o arriba al llegar al final de las páginas más largas.
              </p>
              <p>
                Mención especial quiero hacer al fondo dinámico de la home page, son pequeñas tuberías que se generan de forma aleatoría cada vez que entras en la página.
              </p>
          <h2>La navegación</h2>
          <p>
            Se trata de una página sencilla con sólo tres secciones, así que decidí que para resoluciones grandes debía estar presente todo el rato formando parte del layout general.
            Para resoluciones de móvil la navegación se oculta para aprovechar toda la anchura de la pantalla.
          </p>
          <ResponsiveImage src={rsaMobileNavigation} />
          <p>
            Si has llegado aquí espero que te haya gustado está página. La he hecho con mucha ilusión.
          </p>
        </Container>
      </ScreenWrapper>
    );
  }
}

export default scrollAtBottom(withScrollProgressBar(Rsa));
