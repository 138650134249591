import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from './ProjectCardStyle.js';

class ProjectCard extends Component {

  render() {
    const {
      name,
      description,
      iconScale,
      bgImage,
      topImage,
      redirectTo
    } = this.props;

  return (
    <Card>
      <Card.Image bgImage={bgImage}>
        <Card.Icon src={topImage} alt={name} iconScale={iconScale}/>
      </Card.Image>
      <Card.Cta>
        <Card.CtaTitle>{name}</Card.CtaTitle>
        <Card.CtaDescription>{description}</Card.CtaDescription>
        <Card.CtaButton to={redirectTo}>Ver proyecto</Card.CtaButton>
      </Card.Cta>
    </Card>
  );
  }
}


ProjectCard.propTypes = {
  name: PropTypes.string,
  iconScale: PropTypes.number,
  description: PropTypes.string,
  topImage: PropTypes.string,
  bgImage: PropTypes.string
};

ProjectCard.defaultProps = {
  iconScale: 1,
  description: ""
};

export default ProjectCard;
