import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {VideoPlayerWrapper, PlayerWrapper} from './VideoPlayerMobileStyle.js';

class VideoPlayerMobile extends Component {

  render() {
    const {
      video,
      children
    } = this.props;


  return (
    <PlayerWrapper>
      <div>
        <VideoPlayerWrapper>
          <video autoPlay muted loop>
            <source src={video} type="video/mp4" />
          </video>
        </VideoPlayerWrapper>
      </div>
      <div>
        {children}
      </div>
    </PlayerWrapper>
  );
  }
}


VideoPlayerMobile.propTypes = {
  video: PropTypes.string.isRequired
};
VideoPlayerMobile.defaultProps = {
  children: ""
};


export default VideoPlayerMobile;
