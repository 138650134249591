const size = {
  mobile: '320px',
  mobileL: '600px',
  tablet: '768px',
  laptop: '1024px',
  desktop: '1224px',
  desktopMainBg: '1370px',
  gameBoyBigDesktop: '1600px'
};

export const device = {
  mobile: `(min-width: ${size.mobile})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopMainBg: `(min-width: ${size.desktopMainBg})`,
  gameBoyBigDesktop: `(min-width: ${size.gameBoyBigDesktop})`,
  verticalMaxMainHome: `(max-height: 840px)`
};

export const fromWidth = (from) => `(min-width: ${from}px)`;
