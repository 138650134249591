import styled, {css} from 'styled-components';
import { device } from 'styles/mediaQueries.js';


const ButtonsWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  height: 50px;
  width: 100%;
  transition: all 0.3s;
  z-index: 1000;
  ${props => props.showButtons ?
    css`
      transform: translateY(0);
    ` :
    css`
      transform: translateY(120px);
    `
  }
`;

const ScrollToTopContainer = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: absolute;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 1001;
  transition: all 0.3s;
  border: solid 4px ${props => props.theme.colorsRSA.second};
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 15px 4px rgba(0,0,0,0.20);


  &:hover {
    transform: translateY(-5px);
  }

  svg {
    transform: scale(0.65);
    path {
     fill: ${props => props.theme.colorsRSA.second};
    }
  }
`;

const BackContainer = styled(ScrollToTopContainer)`
  @media ${device.tablet} {
    left: 220px;
  }
  left: 20px;
  right: auto;
  transform: rotate(-90deg);
  &:hover {
    transform: translateX(-5px) rotate(-90deg);
  }
`;



export {ScrollToTopContainer, BackContainer, ButtonsWrapper};
