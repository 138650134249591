import React, { Component } from 'react';
import { BrowserRouter as Router, withRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from './styles/globalStyles';
import { ThemeRsa }  from './styles/theme';
import { OuterContainer, MainContainer } from 'styles/shared/layoutStyles.js';
import { RootRoutes, Paths } from 'routes/RootRoutes';
import Menu from 'components/menu/Menu';
import ScrollToTop from 'components/hocs/scrollToTop/ScrollToTop';

class App extends Component {
  render() {
    const ContentWithRouter = withRouter(props => {
      const isHome = props.location.pathname === Paths.home;
      const content = (<ScrollToTop>
                        <RootRoutes />
                       </ScrollToTop>);
      if (isHome) {
        return (
          <MainContainer>
            {content}
          </MainContainer>
        )
      } else {
        return(
          <div>
            <Menu/>
            <MainContainer withMenu>
              {content}
            </MainContainer>
          </div>
      );
      }
    });


    return (
      <Router>
        <ThemeProvider theme={ThemeRsa}>
          <OuterContainer>
            <GlobalStyle />
            <ContentWithRouter />
          </OuterContainer>
        </ThemeProvider>
      </Router>
    );
  }
}

export default App;
