import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { HamburgerContainer, HamburgerBox, HamburgerInner} from './HamburgerStyle.js';

class Hamburger extends Component {

  render() {
    return (
      <HamburgerContainer isMenuOpened={this.props.isMenuOpened} onClick={this.props.onClick} shouldBeWhite={this.props.shouldBeWhite}>
        <HamburgerBox>
          <HamburgerInner shouldBeWhite={this.props.shouldBeWhite}/>
        </HamburgerBox>
      </HamburgerContainer>
    );
  }
}

Hamburger.propTypes = {
  isMenuOpened: PropTypes.bool,
  shouldBeWhite: PropTypes.bool,
  onClick: PropTypes.func
};

Hamburger.defaultProps = {
  isMenuOpened: false,
  shouldBeWhite: false,
  onClick: () => {}
};

export default Hamburger;
