import styled, {css} from 'styled-components';
import { device } from 'styles/mediaQueries.js';
import { Link } from 'react-router-dom';

const HomeContainer = styled.div`
  height: 100vh;
  @media ${device.mobileL} {
    @media ${device.verticalMaxMainHome} {
      height: auto;
    }
  }

`;

const HomeContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font: 400 1.1em ${props => props.theme.fontFamilyTitleAlternative};
    color: ${props => props.theme.baseTextColor};
    text-transform: uppercase;
    letter-spacing: 10px;
    text-align: center;
    @media ${device.mobileL} {
      font: 400 1.5em ${props => props.theme.fontFamilyTitleAlternative};
    }

    span {
      font: 400 .7em ${props => props.theme.fontFamilyTitle};
      letter-spacing: 5px;
      text-transform: lowercase;
      @media ${device.mobileL} {
        font: 400 .6em ${props => props.theme.fontFamilyTitle};
      }

    }
  }
`;

const LinksContainer = styled.div`
  display: flex;
  padding: ${props => props.theme.mediumPadding} ${props => props.theme.xLargePadding} ${props => props.theme.xxLargePadding};
  justify-content: space-between;
  max-width: 700px;
  width: 100%;
  position: relative;
  @media ${device.mobileL} {
    padding: ${props => props.theme.xLargePadding} ;
  }

`;

const LinkLabel = styled.div`
  color: #555;
  text-transform: uppercase;
  font: normal 400 .6em ${props => props.theme.fontFamilyTitle};
  position: relative;
  text-align: center;
  margin-top: ${props => props.theme.mediumMargin};
  transition: all .2s linear;
  @media ${device.mobileL} {
    font: normal 400 .8em ${props => props.theme.fontFamilyTitle};
  }
`;

const LinkHome = styled(Link)`

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: all .2s linear;
  transform: scale(1);

  &:hover,
  &:active{
    transform: scale(1.1);
  }

  &:hover ${LinkLabel} {
    color: ${props => props.theme.colorsRSA[props.categoryStyle]};
  }

  &:hover svg path {
    fill: ${props => props.theme.colorsRSA[props.categoryStyle]};
  }

  svg {
    ${props=> props.iconHeight ? css`
      height: ${props.iconHeight - 25}px;
    ` : css`
      height: 60px;
    `}
    @media ${device.mobileL} {
      ${props=> props.iconHeight ? css`
        height: ${props.iconHeight}px;
      ` : css`
        height: 90px;
      `}
    }

    path {
      transition: fill .2s linear;
      fill: #555;
    }
  }
`;
const Separator = styled.hr`
  height: 1px;
  max-width: 800px;
  width: 90%;
  border: none;
  background-color: #ccc;
  color: #ccc;
  margin:  ${props => props.theme.xxLargeMargin} 0 ${props => props.theme.largeMargin};
  @media ${device.mobileL} {
    margin:  ${props => props.theme.largeMargin} 0;
  }
`

export {HomeContainer, HomeContent, LinksContainer, LinkHome, LinkLabel, Separator};
