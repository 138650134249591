import styled, {css} from 'styled-components';
import { device } from 'styles/mediaQueries.js';

const HamburgerContainer = styled.div`
    display: inline-block;
    overflow: visible;
    ${ props => props.shouldBeWhite ? css`
      margin: ${props => props.theme.mediumMargin};
      padding: ${props => `${props.theme.mediumPadding} ${props.theme.mediumPadding} ${props.theme.smallPadding}`};
      background-color: rgba(0,0,0,0.5);
      ` : css`
      margin: ${props => props.theme.largeMargin};
      background-color: transparent;
    `}

    cursor: pointer;
    text-transform: none;
    color: inherit;
    border: 0;
    position:   ${props => props.isMenuOpened ? 'fixed' : 'absolute'};
    left: 0;
    top: 0;
    z-index: 10001;
    transition: transform 0.3s ease;
    @media ${device.tablet} {
      display: none;
    }
    ${props => props.isMenuOpened && `
      transform: translateX(${props.theme.menuWidth});
      ${HamburgerInner} {
        transition-delay: .32s;
        transition-timing-function: cubic-bezier(.215,.61,.355,1);
        transform: rotate(45deg);
        &:before {
          top: 0;
          transition: top 75ms ease .32s,opacity 75ms ease .32s;
          opacity: 0;
        }
        &:after {
          bottom: 0;
          transition: bottom 75ms ease .32s,transform 75ms cubic-bezier(.215,.61,.355,1) .32s;
          transform: rotate(-90deg);
        }
      }
    `}
`;
const HamburgerBox = styled.div`
    position: relative;
    display: inline-block;
    width: ${props => props.theme.hamburgerSize};
    height: ${props => props.theme.hamburgerSize};
`;
const HamburgerInner = styled.div`
    transition-timing-function: cubic-bezier(.55,.055,.675,.19);
    transition-duration: 75ms;
    background-color: ${props => props.shouldBeWhite ? props.theme.hamburgerColorWithBg : props.theme.hamburgerColor};
    position: absolute;
    width: ${props => props.theme.hamburgerSize};
    height: 4px;
    transition-property: transform;
    border-radius: 4px;
    top: 50%;
    display: block;
    margin-top: -2px;
    z-index: 100000;

    &:before, &:after {
      display: block;
      content: "";
      position: absolute;
      z-index: 100001;
      width: ${props => props.theme.hamburgerSize};
      height: 4px;
      transition-timing-function: ease;
      transition-duration: .15s;
      transition-property: transform;
      border-radius: 4px;
      background-color: ${props => props.shouldBeWhite ? props.theme.hamburgerColorWithBg : props.theme.hamburgerColor};
    }
    &:before {
      top: -10px;
      transition: top 75ms ease .32s,opacity 75ms ease;
    }
    &:after {
      bottom: -10px;
      transition: bottom 75ms ease .32s,transform 75ms cubic-bezier(.55,.055,.675,.19);
    }
`;

export {HamburgerContainer, HamburgerBox, HamburgerInner};
