import React, { Component } from 'react';
import {MenuContainer, MenuCover, NavContainer, LogoMenu, LogoMenuName, MenuFooter} from './MenuStyle.js';
import Hamburger from 'components/hamburger/Hamburger';
import BackButton from 'components/backButton/BackButton';
import MenuItem from 'components/menuItem/MenuItem';
import { shouldHeaderBeWhite, shouldHaveBackButton, Paths } from 'routes/RootRoutes';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";

import { ReactComponent as ProjectIcon } from 'img/menu/projects-icon.svg';
import { ReactComponent as ResumeIcon } from 'img/menu/resume-icon.svg';
import { ReactComponent as ContactIcon } from 'img/menu/contact-icon.svg';

const icons = [ ProjectIcon, ResumeIcon, ContactIcon];
const items = [ {
  title: "Proyectos",
  itemStyle: "first",
  iconHeight: 45,
  to: Paths.work
},
{
  title: "CV",
  itemStyle: "second",
  iconHeight: 55,
  to: Paths.cv,
},
{
  title: "Contacto",
  itemStyle: "third",
  iconHeight: 55,
  to: Paths.contact
}];


class Menu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      active: false,
      redirect: false,
      clickedItem: 0,
    };
    this.onMenuOpen = this.onMenuOpen.bind(this);
    this.onHideMenu = this.onHideMenu.bind(this);
    this.onGoBack = this.onGoBack.bind(this);
  }

  onMenuOpen() {
    this.setState(prevState => ({
      active: !prevState.active
    }));
  }

  onHideMenu() {
    this.setState(prevState => ({
      active: false
    }));
  }

  onGoBack() {
    this.context.router.history.goBack();
  }

  getItems() {
    return items.map((item, index) => {
      return (
        <MenuItem {...item} key={item.title} onClick={this.onHideMenu}>
          {icons[index]}
        </MenuItem>
      );
    });
  }

  shouldBeWhite() {
    return shouldHeaderBeWhite(this.props.location.pathname);
  }

  getBackButton() {
    return shouldHaveBackButton(this.props.location.pathname) ? <BackButton isMenuOpened={this.state.active} onClick={this.onGoBack} shouldBeWhite={this.shouldBeWhite()} /> : null;
  }

  render() {
    return (
      <div>
        <NavContainer isMenuOpened={this.state.active}>
          <LogoMenu href={Paths.home} onClick={this.onHideMenu}>
            <LogoMenuName>
              <span>Rafael</span>
              <span>Salgado</span>
              <span>Almazán</span>
            </LogoMenuName>
          </LogoMenu>
          <MenuContainer>
            {this.getItems()}
          </MenuContainer>
          <MenuFooter>
          Frontend developer
          </MenuFooter>
        </NavContainer>
        <Hamburger isMenuOpened={this.state.active} onClick={this.onMenuOpen} shouldBeWhite={this.shouldBeWhite()}/>
        {this.getBackButton()}
        <MenuCover isMenuOpened={this.state.active} onClick={this.onHideMenu}/>
    </div>
    );
  }
}

Menu.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired
    }).isRequired,
    staticContext: PropTypes.object
  }).isRequired
};

export default withRouter(Menu);
