import React, { Component } from 'react';
import ScrollToTop from 'components/scrollToTop/ScrollToTop';

export default function withScrollProgressBar(WrappedComponent) {
  return class extends Component {
    render() {
      return (
        <div>
          <WrappedComponent />
          <ScrollToTop/>
        </div>
      );
    }
  }
}
