import React from 'react';
import PropTypes from 'prop-types';
import {ScrollToTopContainer, BackContainer, ButtonsWrapper } from './ScrollToTopStyle.js';
import { ReactComponent as TopArrowIcon } from 'img/scroll-to-top-arow.svg';

const SCROLL_THRESHOLD = 2;

class ScrollToTop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showButtonToTop: false
    };
    this.onScroll = this.onScroll.bind(this);
    this.goBack = this.goBack.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);

  }

  toggleButtonState(visible) {
    this.setState({
      showButtonToTop: visible
    });
  }

  isBottom() {
    let winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    let height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    let scrolled = 100 - (winScroll / height) * 100;
    return scrolled <= SCROLL_THRESHOLD;
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  goBack() {
    this.context.router.history.goBack();
  }

  onScroll(e) {
    if (this.isBottom()) {
      setTimeout(() => { this.toggleButtonState(true) } , 700);
    } else {
      if (this.state.showButtonToTop) {
        setTimeout(() => { this.toggleButtonState(false) } , 700);
      }
    }

  }

  componentDidMount() {
    document.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll);
  }

  render() {
    return (
      <ButtonsWrapper showButtons={this.state.showButtonToTop}>
        <ScrollToTopContainer onClick={this.scrollToTop} >
          <TopArrowIcon />
        </ScrollToTopContainer>
        <BackContainer onClick={this.goBack} >
          <TopArrowIcon />
        </BackContainer>
      </ButtonsWrapper>
    )
  }
}

ScrollToTop.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired
    }).isRequired,
    staticContext: PropTypes.object
  }).isRequired
};

export default ScrollToTop;
