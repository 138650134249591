import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BackButtonContainer, BackButtonWrapper} from './BackButtonStyle.js';
import { ReactComponent as BackIcon } from 'img/back.svg';


class BackButton extends Component {

  render() {
    return (
      <BackButtonContainer isMenuOpened={this.props.isMenuOpened} onClick={this.props.onClick} shouldBeWhite={this.props.shouldBeWhite}>
        <BackButtonWrapper>
          <BackIcon />
          <span>ATRÁS</span>
        </BackButtonWrapper>
      </BackButtonContainer>
    );
  }
}

BackButton.propTypes = {
  isMenuOpened: PropTypes.bool,
  shouldBeWhite: PropTypes.bool,
  onClick: PropTypes.func
};

BackButton.defaultProps = {
  isMenuOpened: false,
  shouldBeWhite: false,
  onClick: () => {}
};

export default BackButton;
