import styled, { keyframes } from 'styled-components';

const INNER_CIRCLE_OPACITY = .65;


const circleSize = (props) => {
  return props.width * props.circleRatio;
}

const innerCircleSize = (props) => {
  return circleSize(props) * props.innerCircleRatio;
}

const circleTopPosition = (circleSize) => {
  return circleSize / 2;
}

const circleLeftPosition = (circleSize, width) => {
  return circleSize / 2 - width / 2;
}

const chooseRotation = (direction) => {
   switch (direction) {
     case 'top':
       return '0';
      case 'bottom':
       return '-180deg'
      case 'left':
        return '-90deg'
      case 'right':
        return '90deg'
     default:
      return '0'
   }
 }

 // Create the keyframes
 function animationGrow (props) {
   return keyframes`
     0% {
       transform: rotate(${chooseRotation(props.direction)}) scaleY(0);
     }
     100% {
       transform: rotate(${chooseRotation(props.direction)}) scaleY(1);
     }
   `;
}

// Create the keyframes
function animationShow (props) {
  return keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `;
}


const PipeContainer = styled.div`
    z-index: ${props => props.zIndex};
    width: ${props => props.width}px;
    height: ${props => props.length}px;
    background-color: ${props => props.color};
    position: fixed;
    bottom: ${props => props.bottomPosition}px;
    right: calc(${props => `${props.rightPosition}%`} + ${props => `${props.rightOffset}px`});
    left: auto;
    animation: ${props => animationGrow} ${props => props.duration}s linear ${props => props.delay}s forwards;
    transform: rotate(${props => chooseRotation(props.direction)}) scaleY(0);
    transform-origin: bottom left;
    opacity: ${props => props.opacity};

    &:before,&:after {
      content: "";
      position: absolute;
      border-radius: 50%;
      opacity: 0;
      animation: ${props => animationShow} .3s linear ${props => props.delay + props.duration}s forwards;
    }
  &:before {
      top: -${ props => circleTopPosition(circleSize(props)) }px;
      left: -${props => circleLeftPosition(circleSize(props), props.width) }px;
      width: ${(props => circleSize)}px;
      height: ${(props => circleSize)}px;
      background-color: ${props => props.color};
    }
  &:after {
    top: -${ props => circleTopPosition(innerCircleSize(props)) }px;
    left: -${props => circleLeftPosition(innerCircleSize(props), props.width) }px;
      width: ${(props => innerCircleSize)}px
      height: ${(props => innerCircleSize)}px
      background-color: #fff;
      opacity: ${INNER_CIRCLE_OPACITY};
    }
`;




export default PipeContainer;
