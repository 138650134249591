export const ThemeRsa = {
  mainColor: "#c600d2",
  baseTextColor: "#222",
  fontFamilyText: "'Raleway', sans-serif",
  fontFamilyTitle: "'Roboto', sans-serif",
  fontFamilyTitleAlternative: "'Abel', serif",
  fontSize: "1.1em",
  lineHeight: "1.40",
  widthSiteWrapper: "800px",
  widthSiteWrapperLarge: "1224px",
  heroHeight: "645px",
  smallMargin: "5px",
  mediumMargin: "10px",
  largeMargin: "20px",
  xLargeMargin: "40px",
  xxLargeMargin: "80px",
  smallPadding: "5px",
  mediumPadding: "10px",
  largePadding: "20px",
  xLargePadding: "40px",
  xxLargePadding: "80px",
  menuWidth: "200px",
  separatorColor: "#ccc",
  hamburgerSize: "35px",
  hamburgerColor: "black",
  hamburgerColorWithBg: "white",
  menuCoverColor: "rgba(255,255,255, 0.55)",
  hamburgerAnimationTime: ".3s",
  fullPanelBgColor: '#eee',
  viewProjectButtonDefaultColor: '#333',
  colorsRSA: {
    first: "#c600d2",
    firstDark: "#9c2ea3",
    second: "#4ad200",
    third: "#00d2d0",
    thirdDark: "#11bebc",
  },
  form: {
    colorError: 'red',
  },
  get rsaGradient() {
    return `linear-gradient(to right, ${this.colorsRSA.first}, ${this.colorsRSA.second}, ${this.colorsRSA.third})`;
  }
};
