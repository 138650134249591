import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from 'screens/home/Home';
import Cv from 'screens/cv/Cv';
import Contact from 'screens/contact/Contact';
import Projects from 'screens/projects/Projects';
import Raffld from 'screens/projects/raffld/Raffld';
import Unno from 'screens/projects/unno/Unno';
import Rsa from 'screens/projects/rsa/Rsa';
import V24 from 'screens/projects/v24/V24';
import Tga from 'screens/projects/tga/Tga';
import Equia from 'screens/projects/equia/Equia';
import Fitwards from 'screens/concepts/fitwards/Fitwards';
import Lottotwee from 'screens/concepts/lottotwee/Lottotwee';

const Paths = {
  home: "/",
  cv: "/cv",
  contact: "/contacto",
  work: "/proyectos",
  projects: {
    raffld: "/proyectos/raffld/",
    unno: "/proyectos/unno",
    rsa: "/proyectos/rsa",
    v24: "/proyectos/v24",
    tga: "/proyectos/tomasgarciaasensio",
    equia: "/proyectos/equia"
  },
  concepts: {
    fitwards: "/proyectos/fitwards",
    lottotwee: "/proyectos/lottotwee",
  }
}

const PathsWithWitheHamburgerIcon = [
  Paths.projects.raffld,
  Paths.projects.tga,
  Paths.projects.rsa,
  Paths.cv,
  Paths.work,
  Paths.contact,
  Paths.concepts.lottotwee
]

const PathsWithBackButton = [
  Paths.projects.unno,
  Paths.projects.rsa,
  Paths.projects.raffld,
  Paths.projects.tga,
  Paths.projects.v24,
  Paths.projects.equia,
  Paths.concepts.lottotwee,
  Paths.concepts.fitwards
]

const RootRoutes = (extraProps) => {
  return (
    <Switch>
      <Route exact path={Paths.home} component={Home} />
      <Route exact path={Paths.cv} component={Cv} />
      <Route exact path={Paths.contact} component={Contact} />
      <Route exact path={Paths.work} component={Projects} />
      <Route exact path={Paths.projects.unno} component={Unno} />
      <Route exact path={Paths.projects.rsa} component={Rsa} />
      <Route exact path={Paths.projects.raffld} component={Raffld} />
      <Route exact path={Paths.projects.v24} component={V24} />
      <Route exact path={Paths.projects.tga} component={Tga} />
      <Route exact path={Paths.projects.equia} component={Equia} />
      <Route exact path={Paths.concepts.fitwards} component={Fitwards} />
      <Route exact path={Paths.concepts.lottotwee} component={Lottotwee} />
    </Switch>
  );
};

const shouldHeaderBeWhite = path => PathsWithWitheHamburgerIcon.find(pathArray => pathArray === path) !== undefined;
const shouldHaveBackButton = path => PathsWithBackButton.find(pathArray => pathArray === path) !== undefined;


export {
  Paths,
  RootRoutes,
  shouldHeaderBeWhite,
  shouldHaveBackButton
};
