import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Field, InputFieldWrapper, Label} from './InputFieldStyle.js';



class InputField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: (props.locked && props.active) || false,
      value: props.value || ''
    }
    this.onChange = this.onChange.bind(this);
  }
  onChange(event) {
    const value = event.target.value;
    this.setState({ value, error: '' });
    return this.props.onChange(event);
  }
  render() {
    const { active, value } = this.state;
    const { id, type, locked, error, label, name } = this.props;

    const isActive = locked ? active : active || value;
    const isLocked = locked && !active;

    return (
      <Field
        isActive = {isActive}
        isLocked = {isLocked}
        error = {error}
      >
        <InputFieldWrapper
            id={id}
            type={type}
            name={name}
            value={value}
            placeholder={label}
            onChange={this.onChange}
            onFocus={() => !locked && this.setState({ active: true })}
            onBlur={() => !locked && this.setState({ active: false })}
        />
        <Label htmlFor={id}>
          {error || label}
        </Label>
      </Field>
    );
  }



}

InputField.propTypes = {
    id: PropTypes.string.isRequired,
    locked: PropTypes.bool,
    active: PropTypes.bool,
    value: PropTypes.string,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    error: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
 };
InputField.defaultProps = {
    locked: false,
    active: false,
    value: '',
    error: '',
    label: '',
    type: 'text',
    onChange: () => '',
 };


export default InputField;
