export const styles = {
  projectGrid: {
    maxColumns: 4,
    maxRows: 3
  },
  ideasGrid: {
    maxColumns: 4,
    maxRows: 1
  }
};
