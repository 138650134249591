import React, { Component } from 'react';
import Container from 'styles/shared/Container.js';
import VideoPlayerMobile from 'components/videoPlayerMobile/VideoPlayerMobile';
import scrollAtBottom from 'components/hocs/scrollAtBottom/ScrollAtBottom';
import withScrollProgressBar from 'components/hocs/withScrollProgressBar/WithScrollProgressBar';
import {
  HeroContainer,
  Hero,
  ScreenWrapper,
  ProjectLogo,
  TechContent,
  TechContentRow,
  TechDescription,
  TechSeparator,
  ScreenshotWrapper,
  MainQuote,
  ResponsiveImage
  } from 'styles/shared/elementsStyles.js';

  import { ViewProjectButton } from 'styles/shared/Buttons.js';

  import {
    UnnoIcons,
    UnnoIcon
  } from './UnnoStyle.js';

import unnoVideo from 'img/projects/unno/unno-video.mp4';
import hero from 'img/projects/unno/unno-hero.jpg';
import logo from 'img/projects/unno/unno-logo-color.svg';
import unnoMobiles from 'img/projects/unno/unno-mobiles.png';
import unnoCombination from 'img/projects/unno/unno-combination.png';
import { ReactComponent as Love } from 'img/projects/unno/symbolicons/love.svg';
import { ReactComponent as Education } from 'img/projects/unno/symbolicons/education.svg';
import { ReactComponent as Happiness } from 'img/projects/unno/symbolicons/happiness.svg';
import { ReactComponent as Health } from 'img/projects/unno/symbolicons/health.svg';
import { ReactComponent as Home } from 'img/projects/unno/symbolicons/home.svg';
import { ReactComponent as Hygiene } from 'img/projects/unno/symbolicons/hygiene.svg';
import { ReactComponent as Nutrition } from 'img/projects/unno/symbolicons/nutrition.svg';
import { ReactComponent as Play } from 'img/projects/unno/symbolicons/play.svg';
import { ReactComponent as Water } from 'img/projects/unno/symbolicons/water.svg';
import screenshot1 from 'img/projects/unno/desktop1.png';
import screenshot2 from 'img/projects/unno/desktop2.png';


class Unno extends Component {

  render() {
    return (
      <ScreenWrapper withTopPadding>
      <HeroContainer>
        <Hero src={hero} alt="Unicef Lotteriet" />
        <ProjectLogo logoHeight="60px">
          <img src={logo} alt="Unicef Lotteriet"  />
        </ProjectLogo>
        <span>Lotería oficial de Unicef en Noruega</span>
        <ViewProjectButton buttonColor="#008be0" href="https://game.uniceflotteriet.no/" target="_blank" rel="noopener noreferrer" >Visitar Projecto</ViewProjectButton>
      </HeroContainer>
        <Container>
          <div>
            <p>
              El proyecto arrancó con un gran reto: <strong>una sóla semana para crear un nuevo de lotería que cumpliese con las especificaciones de la licencía y que le gustasé a Unicef Noruega.</strong>
            </p>
            <p>Pues lo conseguimos, en esa semana nació <strong>Unicef Lotteriet</strong>. Un bote no muy alto, probabilidades de llevarte un premio altas y resaltar los fines sociales de esta lotería.</p>
            <ResponsiveImage src={unnoCombination} alt="5 números y un símbolo de Unicef" />
            <MainQuote>
              <p>La idea fue conectar tu combinación de lotería con una de las areas de trabajo de Unicef, <strong>5 números + 1 símbolo de Unicef</strong>.</p>
              <p>Cada símbolo explicaba a que destina Unicef Noruega el dinero recaudado. </p>
            </MainQuote>
            </div>
            <UnnoIcons>
              <UnnoIcon>
                <Love />
                <span>Love</span>
              </UnnoIcon>
              <UnnoIcon>
                <Home />
                <span>Home</span>
              </UnnoIcon>
              <UnnoIcon>
                <Play />
                <span>Play</span>
              </UnnoIcon>
              <UnnoIcon>
                <Health />
                <span>Health</span>
              </UnnoIcon>
              <UnnoIcon>
                <Water />
                <span>Water</span>
              </UnnoIcon>
              <UnnoIcon>
                <Happiness />
                <span>Happiness</span>
              </UnnoIcon>
              <UnnoIcon>
                <Nutrition />
                <span>Nutrition</span>
              </UnnoIcon>
              <UnnoIcon>
                <Education />
                <span>Education</span>
              </UnnoIcon>
              <UnnoIcon>
                <Hygiene />
                <span>Hygiene</span>
              </UnnoIcon>
            </UnnoIcons>
        </Container>
        <TechContentRow>
          <TechContent>
          <VideoPlayerMobile video={unnoVideo}>
            <p><strong>Webapp con espíritu de App nativa</strong></p>
            <p>La tráfico en móvil en Noruega es elevadísimo, por eso desde el principio el móvil fue el dispositivo para el que se pensó el juego.</p>
            <p>El problea era que tanto los market place de Google y Apple tienen vetados las loterías al considerarlas Gambling, así que una App nativa quedó descartada.</p>
            <p>La solución fue crear una single page application en React que diese la sensación de estar usando una App.</p>
          </VideoPlayerMobile>
          </TechContent>
        </TechContentRow>
        <Container>
            <div>
            <p>Mi implicación en este proyecto fue muy elevada ya que participe en todas las fases del mismo, desde el product discovery hasta la implementación y lanzamiento.</p>
          </div>
        </Container>
        <TechContentRow>
          <TechContent>
          <ul>
            <li>Java</li>
            <li>Spring Boot</li>
            <li>RestFul Api</li>
            <li>OAuth 2</li>
            <li>React</li>
            <li>Webpack</li>
            <li>SASS</li>
            <li>Css Modules</li>
            <li>Wordpress</li>
          </ul>
          </TechContent>
          <TechSeparator />
          <TechDescription>
            Se creó un backend basado en microservicios en Java con Spring Boot y una API REST para poder nutrir al frontend.<br/>
            El frontend constaba de 2 partes:
            <ul>
              <li>Una single page application implementada con <strong>React</strong> para la parte del juego, cuenta de usuario (login, registro, area privada...) y el proceso de compra.</li>
              <li>Un <strong>Wordpress</strong> para la landing principal y para crear las páginas corporativas (política de cookies, normas de usuario, artículos). Se creó un plugin para poder reutilizar componentes creados en el SPA.</li>
            </ul>
            <p>
              Desarrollado con metodología <strong>Scrum</strong> con un equipo distribuido en varias localizaciones. El Product Owner, Scrum Master y parte del equipo de desarrollo estaban en <strong>Londres</strong>, la otra parte estabamos en <strong>Madrid</strong> y el equipo de QA en Ucrania.
            </p>
            <p>
              Los sprints eran de dos semanas y todas las reuniones eran por Hangouts.
            </p>
          </TechDescription>
        </TechContentRow>
        <Container>
            <h2>Retos</h2>
              <p>
                <strong>Interfaz de juego</strong><br/>
                Se quisó innovar creando una interfaz circular a modo de ruleta para elegir tu combinación. Está interfaz estaba optimizada para móvil de modo que había que crear una interfaz completamente disinta para desktop.
              </p>
              <p>
                <strong>BankId</strong><br/>
                Una de las restricciones del proyecto era poder verificar la identidad y la edad del usuario al registrarse. Hubo que integrar BankId, un sistema muy extendido en Noruega donde los usuarios se identifican a través de su movil para verificar su identidad.
              </p>
              <p>
                <strong>Accesibilidad</strong><br/>
                A mitad del proyecto surgió la necesidad de que la página cumpliese con las pautas de accesibilidad propuestas en la WCAG 2.0. Hubo que hacer muchas adaptaciones a nivel de usabilidad y diseño para cumplirlas.
              </p>
              <p>
                <strong>Tracking</strong><br/>
                Hicimos una implementación de Google Tag Manager y Google Analytics especial para poder tener una buena analítica web en una single page application.
              </p>
              <p>
              Además, al traterse de un eCommerce trabajamos con muchos programas de afiliados para cuya integración el uso de Google Tag Manager fue esencial.
              </p>
        </Container>
        <ScreenshotWrapper withBgColor>
          <div><img src={screenshot1} alt="Euromillones en Ventura24" /></div>
          <div><img src={screenshot2} alt="Premios repartidos" /></div>
        </ScreenshotWrapper>
        <Container>
          <p>
            Fue todo un reto crear un producto para Noruega en un idioma ajeno en un equipo distribuido entre Londres, Madrid y Ucrania. Hubo complicaciones como llevar a cabo varios rediseños o adaptarnos a necesidades de accesibilidad. Al final el proyecto vio la luz con el visto bueno de Unicef Internacional.
          </p>
          <ResponsiveImage src={unnoMobiles} />
        </Container>
      </ScreenWrapper>
    );
  }
}

export default scrollAtBottom(withScrollProgressBar(Unno));
