import React from 'react';
import { ScrollBarContainer } from './ScrollBarStyle.js';


/** ManageScrollBar */
class ScrollBar extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        scrollBarRate: 0
      };
      this.ScrollRateCalculation = this.ScrollRateCalculation.bind(this);
    }

    ScrollRateCalculation() {
      let winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      let height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      let scrolled = 100 - (winScroll / height) * 100;
      this.setState({
        scrollBarRate: scrolled
      });
    }

    componentDidMount() {
      this.ScrollRateCalculation();

      document.addEventListener('scroll', this.ScrollRateCalculation);
      window.addEventListener('hashchange', this.ScrollRateCalculation);
      document.addEventListener('click', this.ScrollRateCalculation);
    }

  render() {
    const isTop = this.state.scrollBarRate === 100;
    const scrollBar = !isTop ? (
      <ScrollBarContainer className="scrollbar" width={this.state.scrollBarRate}/>
    ) : null;
    return (
      <div>
        {scrollBar}
      </div>
    )
  }
}


export default ScrollBar;
