import styled from 'styled-components';
import videoBg from 'img/projects/mobile-video-bg.png';
import { device } from 'styles/mediaQueries.js';

const PlayerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  & > div {
    flex: 1 1 0;
    padding: ${props => props.theme.mediumPadding}
  }
  @media ${device.tablet} {
    flex-direction: row;
  }
`;

const VideoPlayerWrapper = styled.div`
  width: 280px;
  height: 600px;
  margin: 0 auto;
  background: url(${videoBg}) no-repeat center;
  display: flex;
  align-items: center;
  justify-content: center;
  video {
    width: calc(100% - 20px);
    height: 100%;
  }
`;


export {VideoPlayerWrapper, PlayerWrapper};
