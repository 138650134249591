import styled, {css} from 'styled-components';
import { device } from 'styles/mediaQueries.js';
import quoteIcon from 'img/projects/quotes.svg';

const QUOTE_ICON_SIZE = 40;

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 100;
  overflow: hidden;

  padding: ${props => props.noPadding ? 0 : '40px 20px 0 20px'};
  & > span {
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-top:10px;
    text-align:center;
    font: 400 1em ${props => props.theme.fontFamilyTitleAlternative};
    padding: 0 ${props => props.theme.mediumPadding};
  }
`;

const MainQuote = styled.div`
  padding: ${props => `${props.theme.largePadding} ${props.theme.xLargePadding}` };
  font-size: 1em;
  line-height: 1.4em;
  font-style: italic;
  position: relative;

  @media ${device.tablet} {
    font-size: 1.2em;
    line-height: 1.8em;
    padding: ${props => `${props.theme.largePadding} ${props.theme.xLargePadding}` };
  }

  &:before {
    content: "";
    position: absolute;
    top: calc(50% - ${QUOTE_ICON_SIZE/2}px - 10px);
    left: -19px;
    width: ${QUOTE_ICON_SIZE}px;
    height: ${QUOTE_ICON_SIZE}px;
    background: url(${quoteIcon}) no-repeat #fff;
    border: 10px solid #fff;
    background-size: contain;
    z-index: 10;

  }
  &:after {
    content: "";
    position: absolute;
    left: 10px;
    top: 20px;
    height: calc(100% - 40px);
    width: 1px;
    background-color: #bbb;
    z-index: 9;
  }
`;

const Hero = styled.img`
  height: auto;
  max-width: 100%;
  object-fit: cover;
`;

const HeroCover = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${props => props.bgImgSmall ? css`
      background: url(${props => props.bgImgSmall}) no-repeat center;
  ` : css`
      background: url(${props => props.bgImgBig}) no-repeat center;
  `
  };
  background-size: cover;
  width: 100%;
  height: ${props => props.theme.heroHeight};

  @media ${device.tablet} {
    background: url(${props => props.bgImgBig}) no-repeat center;
    background-size: cover;
  }
  ${props => props.darked && css`
    position: relative;
    &:after{
      content:"";
      position: absolute;
      background: rgba(0,0,0, 0.25);
      width: 100%;
      height: 100%;
      left:0;
      top:0;
    }
  `
  };
`;

const HeroCoverContent = styled.div`

  display: flex;
  flex-direction: column;
  align-items: center;
  h1, a {
    color: #fff;
  }
  h1 {
    font: 300 2.5em ${props => props.theme.fontFamilyText};
    @media ${device.mobileL} {
      font: 300 3.5em ${props => props.theme.fontFamilyText};
    }
    text-align: center;
    text-transform: capitalize;
  }
`;

const ProjectLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 40px auto auto auto;
  height: ${props => props.logoHeight ? `calc(${props.logoHeight} - 25px)` : 'auto'};
  padding: 0 ${props => props.theme.mediumPadding};
  @media ${device.mobileL} {
    height: ${props => props.logoHeight ? props.logoHeight : 'auto'};
    padding: 0;
  }
  & > img {
    max-height: 100%;
    width: auto;
  }
`;

const TechContentRow= styled.div`
  background-color: #eee;
  padding: ${props => props.theme.xLargePadding} ${props => props.theme.largePadding};
`;

const TechDescription = styled.div`
  color: #666;
  width: 100%;
  max-width: ${props => props.theme.widthSiteWrapper};
  position: relative;
  margin-right: auto;
  margin-left: auto;
  text-shadow: rgba(255,255,255) 1px 1px 1px;
  padding: ${props => props.theme.xLargePadding} ${props => props.theme.largePadding};
  @media ${device.desktop} {
    max-width: ${props => props.theme.widthSiteWrapperLarge};
  }
`;
const TechSeparator= styled.hr`
width: 100%;
max-width: ${props => props.theme.widthSiteWrapper};
  background-color: #666;
  border-bottom: 1px solid #fff;
  margin: ${props => props.theme.largeMargin} auto;
`;
const TechContent= styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${props => props.theme.widthSiteWrapper};
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    li {
      margin: ${props => props.theme.mediumMargin};
      color: transparent;
      text-shadow: rgba(255,255,255) 1px 1px 1px;
      color: #666;
      font-size: 0.9em;
      font-weight: 700;
      padding: ${props => props.theme.smallPadding} ${props => props.theme.largePadding};
      border: 1px solid #666;
      display: flex;
      align-items: center;
      @media ${device.tablet} {
        font-size: 1.1em;
        margin: ${props => props.theme.smallMargin};
      }
    }
  }
`;

const ScreenWrapper = styled.div`
  ${props => props.withTopPadding && css`
    padding-top: ${props => props.theme.xLargePadding};
  `}
`;
const ProjectImageGrid = styled.div`
  @media ${device.mobileL} {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 350px);
    grid-auto-flow: dense;
    grid-gap: 0;
    img {
      max-width: 100%;
      heigh: auto;

    }
  }
`;

const ProjectImageRow = styled.div`
  display: flex;


`;

const ScreenshotWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  ${props => props.withBgColor && css`
      background-color: ${props => props.theme.fullPanelBgColor};
      padding: ${props => props.theme.xLargePadding} ${props => props.theme.largePadding};
  `};

  > div > img {
    margin: 20px 0;
    box-shadow:  0px 0px 15px 2px rgba(0,0,0,0.10);
    max-width: 100%;
    height: auto;
  }

`;

const Columns = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  flex-direction: column;
  @media ${device.desktop} {
    flex-direction: row;
  }
`;

const Column = styled.div`

`;

const ResponsiveImage = styled.img`
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
`;

const TitleSection = styled.div`
  text-align: center;
  position: relative;

  ${props => props.largeTopPadding ? css`
      margin: 120px 0 ${props => props.theme.xLargeMargin};
    `: css`
      margin: ${props => props.theme.xLargeMargin} 0;
    `
    }

  span {
    background: #fff;
    padding: 0 ${props => props.theme.largePadding};
    text-transform: uppercase;
    font-size: 1.2em;
    color: ${props =>  props.category ? props.theme.colorsRSA[props.category] : props.theme.colorsRSA.first};
    ${props => props.twoLines && css`
      display: block;
      width: 75%;
    `};
    margin: 0 auto;
    @media ${device.mobileL} {
      display: inline;
      width: auto;

    }
    @media ${device.tablet} {
      font-size: 1.4em;
    }
  }
  &:after {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    height: 1px;
    width: 100%;
    border-top: 2px dotted #000;
    z-index: -1
  }
`;



export { HeroContainer,
  ProjectLogo,
  Hero,
  HeroCover,
  HeroCoverContent,
  TechContent,
  TechContentRow,
  TechDescription,
  TechSeparator,
  ScreenWrapper,
  ProjectImageGrid,
  ProjectImageRow,
  ScreenshotWrapper,
  MainQuote,
  Columns,
  Column,
  ResponsiveImage,
  TitleSection
 };
