import styled from 'styled-components';
import {CardCtaButton} from 'styles/shared/Buttons';

const Card = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Image = styled.div`
  transition: transform 0.3s ease;
  width: 100%;
  height: 100%;
  color: #fff;
  background: url(${props => props.bgImage}) center;
  background-size: cover;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  ${Card}:hover &{
    transform: translateY(-100%);
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: .60;
    top: 0;
    left: 0;
    z-index: 0;
  }

`;

const Icon = styled.img`
  transform: scale(${props => props.iconScale});
  z-index: 10;
`;

const Cta = styled.div`
  transition: transform 0.3s ease;
  padding: 20px;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colorsRSA.firstDark};
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${Card}:hover &{
    transform: translateY(-100%);
  }
`;

const CtaTitle = styled.div`
 font: 1.2em ${props => props.theme.fontFamilyText};
 text-align: center;
 text-transform: uppercase;
 margin: 10px 0;
`;

const CtaDescription = styled.div`
  font: 300 0.8em ${props => props.theme.fontFamilyText};
  text-align: center;
`;



Card.Image = Image;
Card.Icon = Icon;
Card.Cta = Cta;
Card.CtaTitle = CtaTitle;
Card.CtaDescription = CtaDescription;
Card.CtaButton = CardCtaButton;


export default Card;
