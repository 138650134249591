import styled from 'styled-components';
import { Link } from 'react-router-dom';

const BODER_DECORATOR_WIDTH = 5;


const MenuItemWrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => props.theme.largePadding};

  border-bottom: 1px solid ${props => props.theme.separatorColor};
  color: ${props => props.theme.baseTextColor};
  position: relative;
  z-index: 0;
  overflow: hidden;
  cursor: pointer;

  &:hover::before {
    transform: translateX(0);
  }

  &:first-child {
    border-top: 1px solid ${props => props.theme.separatorColor};
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: ${props => props.theme.colorsRSA[props.itemStyle]};
    z-index: 10;
    transform: translateX(calc(${BODER_DECORATOR_WIDTH}px - 100%));
    transition: all 300ms;
  }
`;

const Icon = styled.div`
  position: relative;
  z-index: 20;
  ${props => props.iconHeight ?
    `height: ${props.iconHeight}px;` :
    `height: 70px;`
  }
  ${MenuItemWrapper}:hover & svg{
    fill: #fff;
  }
`;

const Label = styled.div`
  text-transform: uppercase;
  font: normal 400 .8em ${props => props.theme.fontFamilyTitle};
  position: relative;
  z-index: 20;
  margin-top: ${props => props.theme.mediumMargin};

  ${MenuItemWrapper}:hover & {
    color: #fff;
  }
`;

MenuItemWrapper.Icon = Icon;
MenuItemWrapper.Label = Label;

export default MenuItemWrapper;
