import { createGlobalStyle } from 'styled-components'
import V24Font from 'fonts/projects/v24/ventura24.ttf'
import styledNormalize from 'styled-normalize'
import { device } from 'styles/mediaQueries.js';

export const GlobalStyle = createGlobalStyle`
  ${styledNormalize}
  @import url('https://fonts.googleapis.com/css?family=Raleway:300,400,700,800|Roboto:400,700|Roboto+Slab:400,700|Abel');

  @font-face {
    font-family: ventura24;
    src: url('${V24Font}');
  }

  html {
    color: ${props => props.theme.baseTextColor};
    font-family: ${props => props.theme.fontFamilyText};
    font-size: ${props => props.theme.fontSize};
    line-height: ${props => props.theme.lineHeight};
  }

  * {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.colorsRSA.first};
    font-weight: bold;
  }

  p {
    font-size: 1em;

    @media ${device.tablet} {
      font-size: ${props => props.theme.fontSize};
      line-height: ${props => props.theme.lineHeight};
    }
  }

  h1, h2, h3 {
    font-family: ${props => props.theme.fontFamilyText};
    font-weight: bold;
    text-transform: uppercase;
  }

  h2 {
    font-size: 1.1em;

    @media ${device.tablet} {
      font-size: 1.3em;
    }
  }

  ul > li {
    margin: ${props => `${props.theme.mediumMargin} 0`};
  }

  video:-internal-media-controls-overlay-cast-button {
    display: none;
  }
`;
