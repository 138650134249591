import React, { Component } from 'react';
import Pipes from 'components/pipes/Pipes';
import MediaQuery from 'react-responsive';
import { Paths } from 'routes/RootRoutes';
import { Link } from 'react-router-dom';
import { PipeContainer } from 'styles/shared/layoutStyles.js';
import {
  HeroContainer,
  Hero
} from 'styles/shared/elementsStyles';
import {
    HomeContainer,
    HomeContent,
    LinksContainer,
    LinkHome,
    LinkLabel,
    Separator
  } from './HomeStyles.js'

import hero from 'img/projects/projects-hero.png';
import { ReactComponent as ProjectIcon } from 'img/menu/projects-icon.svg';
import { ReactComponent as ResumeIcon } from 'img/menu/resume-icon.svg';
import { ReactComponent as ContactIcon } from 'img/menu/contact-icon.svg';

class Home extends Component {
  render() {
    return (
      <HomeContainer>
        <PipeContainer>
          <MediaQuery minWidth={1370}>
            <Pipes />
          </MediaQuery>
          <HomeContent>
            <HeroContainer>
            <Link to={Paths.work}>
              <Hero src={hero} alt="RSA - Rafael Salgado Almazán" />
            </Link>
            </HeroContainer>
            <h1>
              RAFAEL SALGADO ALMAZÁN<br/>
              <span>frontend developer</span>
            </h1>
            <Separator />
            <LinksContainer>
              <LinkHome to={Paths.work} iconHeight={60} categoryStyle="first">
                <ProjectIcon width="90px"/>
                <LinkLabel>Proyectos</LinkLabel>
              </LinkHome>
              <LinkHome to={Paths.cv} iconHeight={65} categoryStyle="second">
                <ResumeIcon width="90px"/>
                <LinkLabel>Mi CV</LinkLabel>
              </LinkHome>
              <LinkHome to={Paths.contact} iconHeight={65} categoryStyle="third">
                <ContactIcon width="90px"/>
                <LinkLabel>Contacto</LinkLabel>
              </LinkHome>
            </LinksContainer>
          </HomeContent>
        </PipeContainer>
      </HomeContainer>
    );
  }
}

export default Home;
