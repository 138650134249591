import React, { Component } from 'react';
import ProjectCard from 'components/projectCard/ProjectCard';
import Grid from './IdeasGridStyle.js';
import { Paths } from 'routes/RootRoutes';

import fitwardsImageBg from 'img/projects/fitwards/fitwards-cardBg.jpg';
import fitwardsImageTitle from 'img/projects/fitwards/fitwards-logo.svg';
import lottoTweeBg from 'img/projects/lottotwee/lottotwee-cardBg.jpg';
import lottoTweeTitle from 'img/projects/lottotwee/lottotwee-logo.svg';


class IdeasGrid extends Component {

  render() {

  return (
    <Grid>
      <Grid.FitwardsCell>
        <ProjectCard
          name="Fitwards"
          description="Retos deportivos con recompensas"
          redirectTo={Paths.concepts.fitwards}
          bgImage={fitwardsImageBg}
          topImage={fitwardsImageTitle}
          iconScale={.3}
        />
      </Grid.FitwardsCell>
      <Grid.LottotweeCell>
        <ProjectCard
          name="lottoTwee"
          description="Encuentra pareja jugando a la lotería, WTF?"
          redirectTo={Paths.concepts.lottotwee}
          bgImage={lottoTweeBg}
          topImage={lottoTweeTitle}
          iconScale={.6}
        />
      </Grid.LottotweeCell>
    </Grid>
  );
  }
}

export default IdeasGrid;
