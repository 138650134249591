import styled, { css, keyframes } from 'styled-components';
import { Link } from 'react-router-dom'
import cvIcon from 'img/resume-icon-white.svg';
import loader from 'img/loader-icon.svg';

const iconSize = 45;

const hoverEffect = props => css`
&:before,
&:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.3s;
  border-radius: 50px;
}

&:before {
  border: 1px solid rgba(255,255,255,0.5);
}

&:after {
  opacity: 0;
  background-color: rgba(255,255,255,0.25);
  transform: scale(0.5,0.5);
}

&:hover::before{
  opacity: 0;
  -webkit-transform: scale(1.2,1.2);
  transform: scale(1.2,1.2);
}

&:hover::after{
  opacity: 1 ;
  -webkit-transform: scale(1,1);
  transform: scale(1,1);
}
`;

const mainButton = props => css`
display:block;
font-weight: 300;
font-size: 0.8em;
padding: 10px;
margin-top:20px;
color: rgba(255,255,255,1);
transition: all 0.5s;
position: relative;
text-align: center;
margin-right: auto;
margin-left: auto;
width: 120px;

`;



const HeroButton = styled.a`
  padding: ${props => props.theme.largePadding} ${props => props.theme.xLargePadding}

  border-radius: 50px;
  font: 400 1em ${props => props.theme.fontFamilyText};
  transition: all 0.5s;
  ${props => props.iconCV && css`
    position: relative;
    padding-left: ${iconSize + 30}px
    background: url(${cvIcon}) no-repeat 20px 8px;
    background-size: ${iconSize}px;
  `}
  ${hoverEffect}
`;

const CardCtaButton = styled(Link)`
  ${mainButton}
  ${hoverEffect}
`;

const ViewProjectButton = styled.a`
  ${mainButton}
  ${hoverEffect}
  font-size: 1em;
  padding: ${props => props.theme.mediumPadding} ${props => props.theme.xLargePadding};
  color: ${props => props.buttonColor ? props.buttonColor : props.theme.viewProjectButtonDefaultColor};
  width: 220px;
  margin: ${props => props.theme.xLargeMargin} 0;
  &:before{
    border: 1px solid ${props => props.buttonColor ? props.buttonColor : props.theme.viewProjectButtonDefaultColor};
    opacity: 0.75;
  }
  &:after{
    background-color: ${props => props.buttonColor ? props.buttonColor : props.theme.viewProjectButtonDefaultColor};

  }
  &:hover:after{
    opacity: 0.20;
  }
`;

const ContactFormButton = styled.button`
  ${mainButton}
  ${hoverEffect}
  background: none;
  border: 0;
  font-size: 1.3em;
  max-width: 320px;
  width: 100%;
  margin-top: ${props => props.theme.xLargeMargin};
  &:before {
    border: 3px solid rgba(255,255,255,0.7);
  }
  &:focus {outline:0;}
  display: flex;
  alig-items: center;
  justify-content: center;

`;
function spin (props) {
  return keyframes`
  from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
  `;
}

const Spinner = styled.div`
  width: 30px;
  height: 30px;
  background: url(${loader}) no-repeat center;
  background-size: contain;
  animation-name: ${props => spin};
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;


export { HeroButton, CardCtaButton, ContactFormButton, ViewProjectButton, Spinner };
