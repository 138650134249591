import styled, {css} from 'styled-components';


const Field = styled.div`
  width: 100%;

  border-radius: 4px;
  position: relative;
  background-color: rgba(255,255,255,0.3);
  transition: 0.3s all;
  margin-bottom: ${props => props.theme.largeMargin};
  padding: 24px 16px 8px 16px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.45);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  }

  ${props => props.error ? css`
    border: 1px solid ${props => props.theme.form.colorError};
  ` : css`
    border: none;
  `}


  ${props => props.isLocked && css`
    pointer-events: none;
  `}

  ${props => props.isActive && css`
    background-color: #ffffff !important;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
  `}

`;

const TextAreaFieldWrapper = styled.textarea`
  width: 100%;
  min-height: 200px;
  position: relative;
  padding: 0;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  background-color: transparent;
  color: #282828;
  outline: none;
  box-shadow: 0px 4px 20px 0px transparent;
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.1s padding ease-in-out;
  -webkit-appearance: none;
  &::placeholder {
    color: rgba(255, 255, 255, 0.8);
  }

  &:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
  }

  &::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
  }
`;



export {Field, TextAreaFieldWrapper};
