import React, { Component } from 'react';
import PipeGenerator from 'components/pipeGenerator/PipeGenerator';
class Pipes extends Component {

  render() {
    return (
      <div>
        <PipeGenerator id="pipe0" area="right" />
        <PipeGenerator id="pipe1" area="right"/>
        <PipeGenerator id="pipe2" area="right"/>
        <PipeGenerator id="pipe3" area="left"/>
        <PipeGenerator id="pipe4" area="left"/>
        <PipeGenerator id="pipe5" area="left"/>
      </div>
    );
  }
}

export default Pipes;
