import styled from 'styled-components';
import { device } from 'styles/mediaQueries.js';
import { styles } from 'styles/sharedStyles.js';

const GRID_HEIGHT_D = 200;
const GRID_HEIGHT_M = 150;

const Grid = styled.div`
  @media ${device.mobileL} {
    display: grid;
    grid-template-columns: repeat(${styles.projectGrid.maxColumns}, 1fr);
    grid-template-rows: repeat(${styles.projectGrid.maxRows}, ${GRID_HEIGHT_D}px);
    grid-gap: 20px;
  }
`;

const GridCell = styled.div`
  height: ${GRID_HEIGHT_M}px;
  margin: 20px auto;
  @media ${device.mobileL} {
    height: ${GRID_HEIGHT_D}px;
    margin: 0;
    grid-column: span ${styles.projectGrid.maxColumns};
  }
`;

const V24Cell = styled(GridCell)`
  grid-column: span ${styles.projectGrid.maxColumns};
`;

const RaffldCell = styled(GridCell)`
  @media ${device.mobileL} {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;
const UnnoCell = styled(GridCell)`
  @media ${device.mobileL} {
    grid-column-start: 3;
    grid-column-end: 5;
  }
`;
const TgaCell = styled(GridCell)`
  @media ${device.mobileL} {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;
const EquiaCell = styled(GridCell)`
  @media ${device.mobileL} {
    grid-column-start: 3;
    grid-column-end: 4;
  }
`;
const RsaCell = styled(GridCell)`
  @media ${device.mobileL} {
    grid-column-start: 4;
    grid-column-end: 5;
  }
`;

Grid.GridCell = GridCell;
Grid.V24Cell = V24Cell;
Grid.RaffldCell = RaffldCell;
Grid.UnnoCell = UnnoCell;
Grid.TgaCell = TgaCell;
Grid.EquiaCell = EquiaCell;
Grid.RsaCell = RsaCell;

export default Grid;
