import React, { Component } from 'react';
import VideoPlayerMobile from 'components/videoPlayerMobile/VideoPlayerMobile';
import Container from 'styles/shared/Container.js';
import withScrollProgressBar from 'components/hocs/withScrollProgressBar/WithScrollProgressBar';
import scrollAtBottom from 'components/hocs/scrollAtBottom/ScrollAtBottom';
import {
  HeroContainer,
  Hero,
  HeroCover,
  ScreenWrapper,
  ProjectLogo,
  TechContent,
  TechContentRow,
  ScreenshotWrapper,
  MainQuote,
  ResponsiveImage} from 'styles/shared/elementsStyles.js';
import {
  Euromillones,
  Primitiva,
  ElGordo,
  Bonoloto,
  Quiniela,
  LoteriaNacional,
  IconsProducts
} from './V24Style.js';
import hero from 'img/projects/v24/v24-hero.png';
import bottomHero from 'img/projects/v24/v24-bottom-hero.jpg';
import logo from 'img/projects/v24/v24-logo-color.svg';
import quinielaVideo from 'img/projects/v24/quiniela.mp4';
import mobileNavigation from 'img/projects/v24/mobile-navigation.png';
import desktopNavigation from 'img/projects/v24/desktop-navigation.png';
import screenshot1 from 'img/projects/v24/euromillones.jpg';
import screenshot2 from 'img/projects/v24/premios-repartidos.jpg';


class V24 extends Component {

  render() {
    return (
      <ScreenWrapper withTopPadding>
        <HeroContainer>
          <Hero src={hero} alt="Ventura24" />
          <ProjectLogo logoHeight="45px">
            <img src={logo} alt="Ventura24"  />
          </ProjectLogo>
          <span>El mejor ecommerce de lotería</span>
        </HeroContainer>
        <Container>
          <div>
            <p>
              Ventura24 nació en 2001 en pleno furor de las empresas puntocom. Terminó su actividad comercial en diciembre de 2018 debido a problemas regulatorios ajenos a la empresa. Durante sus 17 años de vida ha sabido adaptarse a todos los cambios que han ido ocurriendo en el mundo online.
            </p>
            <MainQuote>
              <p>Ventura24 comercializaba productos de loterías del estado (Euromillones, La Primitiva, Lotería de Navidad...) ofreciendo servicios de valor añadido para el cliente.</p>
              <p>El éxito de Ventura24 radicó en saber trasladar un negocio muy tradicional y puramente offline al mundo online sin perder la esencia del producto.</p>
            </MainQuote>
            <p>Ventura24 siempre ha sido el <strong>referente</strong> en la comercialización de lotería por Internet en España, marcando tendencía en cuanto funcionalidades y usabilidad a la hora de jugar a la lotería online.</p>
          </div>
        </Container>
        <TechContentRow>
          <TechContent>
          <ul>
            <li>Java</li>
            <li>Spring Boot</li>
            <li>Thymeleaf</li>
            <li>Maven</li>
            <li>jQuery</li>
            <li>requireJS</li>
            <li>SASS</li>
            <li>Grunt</li>
          </ul>
          </TechContent>
        </TechContentRow>
        <Container>
          <div>
            <h2>Un viaje por la historía de Internet</h2>
            <p>
              Podemos decir que Ventura24 ha sido viaje por la historía de las tecnologías webs, empezó en su día con una simple maquetación con tablas, después implementamos los tickets de lotería en Flash, rediseñamos la web con css posicionando las capas mediante floats.
              Obviamente flash quedó obseleto y tocó implementar los tickets en Javascript y jQuery. Creamos un eCommerce paralelo para móvil con jQuery Mobile. ¡Menudo viaje con curvas!
            </p>
              <h2>Creando una identidad propia</h2>
            <p>
              Finalmente, en 2016 lanzamos un rediseño responsive para poder tener una sóla web. Con la ayuda de los cracks de <a href="https://www.redbility.com/" target="_blank" rel="noopener noreferrer">Redbility</a>, se creó una imagen corporativa propia, una nueva estructura y navegación, se rediseñaron todos los productos y sus interacciones y mejoraron los servicios.
            </p>
            <IconsProducts>
              <Euromillones />
              <Primitiva />
              <Bonoloto />
              <ElGordo />
              <Quiniela />
              <LoteriaNacional />
            </IconsProducts>
            <p>
              Nos adaptamos a los nuevos tiempos sin alejarnos de nuestros clientes más tradicionales.<br/>
              Cada producto tenía su icono y su color propio dando robustez al diseño y pudiendo destacar cada producto donde lo necesitasemos.
            </p>
            </div>
        </Container>
        <ScreenshotWrapper>
          <div><img src={screenshot1} alt="Euromillones en Ventura24" /></div>
          <div><img src={screenshot2} alt="Premios repartidos" /></div>
        </ScreenshotWrapper>
        <Container>
          <p>
            Nos adaptamos a los nuevos tiempos sin alejarnos de nuestros clientes más tradicionales.<br/>
            Cada producto tenía su icono y su color propio dando robustez al diseño y pudiendo destacar cada producto donde lo necesitasemos.
          </p>
        </Container>
        <TechContentRow>
          <TechContent>
          <VideoPlayerMobile video={quinielaVideo}>
            <p><strong>Micro-interacciones</strong></p>
            <p>Las micro-interacciones juegan un papel importante a la hora de hacer más claro el proceso de rellenar un ticket de lotería.</p>
            <p>El interfaz irá enseñando las reglas del juego según el usuario interactue con el ticket.</p>
            <p>Aquí el usuario rellena una quiniela de forma aleatoria. El proceso debe ser sencillo tanto para usuarios que ya hayan jugado a la quiniela como para los que sea la primera vez.</p>
          </VideoPlayerMobile>
          </TechContent>
        </TechContentRow>
        <Container>
          <div>
          <h2>Una navegación a medida</h2>
          <p>
            El número de productos y servicios había crecido mucho a lo largo de los años, además habiamos aprendido que había productos más importantes que otros así que tener una buena jerarquía de la infomación y una navegación que se adecuase a nuestras necesidades era un reto.
          </p>
          <ResponsiveImage src={desktopNavigation} alt="Desktop Navigation" />
          <p>Los productos se ordenaban de izquierda a derecha según prioridad y según se iba reduciendo la pantalla los productos que no cabían se iban metiendo en submenu "+ Productos". De está forma los productos importantes siempre estaban visibles.</p>
          <ResponsiveImage src={mobileNavigation} alt="Mobile Navigation" />
          <h2>Desarrollo de funcionalidades con el usuario como protagonista</h2>
            <p>
              Las decisiones que tomabamos a la hora de desarrollar no se basaban en impulsos locos de la gente de producto, todo lo constrastabamos y validabamos con los usuarios. Utilizabamos todas las técnicas y tecnologías a nuestro alcance para conocer a los usuarios y poder ofrecerles los mejores servicios:
            </p>
            <ul>
              <li><strong>Test de usuarios</strong>: Toda funcionalidad era ideada y prototipada antes de implementarla. Invitabamos a nuestros usuarios a nuestras oficinas a usar estos prototipos para ver como reaccionaban y ver si se atascaban en algún punto. Con esta información iterabamos la idea hasta estar satisfechos.</li>
              <li><strong>A/B Testing</strong>: Cuando queriamos optimizar los procesos de nuestra web utilizamos los test AB para obtener información cuantitativa sobre que alternativa funcionaba mejor. Así podiamos mejorar Formularios de registro, tickets de lotería, procesos de pago... con datos reales. </li>
              <li><strong>Focus groups</strong>: Con ayuda de empresas especializadas queriamos conocer de primera mano los comportamientos de jugadores online y offline de lotería. En reuniones de grupos pequeños de perfiles determinados obtiniamos información cualitativa muy útil.</li>
              <li><strong>Herramientas de visualización de usuarios</strong>: Utilzamos herramientas como <a href="https://www.hotjar.com/" target="_blank" rel="noopener noreferrer">Hotjar</a> para obtener grabaciones de sesiones de usuario pudiendo observar como se enfrentaban a nuestros interfaces de usuario.</li>
              <li><strong>Feedback de usuario</strong>: Dabamos la oportunidad a nuestros usuarios de darnos feedback al terminar algún proceso "complejo" cómo el registro o el proceso de compra.</li>
              <li><strong>Analitica web</strong>: Google Tag Manager y Google Analytics eran nuestro mejores amigos a la hora de saber que estaba pasando en nuestra web, cuales eran nuestros indicadores, de donde venía el tráfico, donde había que mejorar... Algo básico en cualquier eCommerce.</li>
            </ul>
            <h2>Metodologías agiles. Scrum</h2>
            <p>
              Con toda la información recogida anteriormente los products owners podian escribir las historias de usuario de forma optima. Usamos Jira para hacer el seguimiento de sprint y manejar el backlog.
              Nuestras sprints eran de 2 semanas y haciamos todas las reuniones necesarias: dailys, refinamiento, sprint planings y retrospectivas.
            </p>

          </div>
        </Container>
        <HeroContainer noPadding>
          <HeroCover bgImgBig={bottomHero}/>
        </HeroContainer>
      </ScreenWrapper>
    );
  }
}

export default scrollAtBottom(withScrollProgressBar(V24));
