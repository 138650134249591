import styled from 'styled-components';
import videoBg from 'img/projects/desktop-video-bg.png';
import { device } from 'styles/mediaQueries.js';


const PlayerWrapper = styled.div`
  background: url(${videoBg}) no-repeat center top;
  background-size: contain;
  max-width: 1305px;
  max-height: 1107px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 30px 30px 150px 30px;
  @media ${device.mobileL} {
    padding: 30px 30px 180px 30px;
  }
  @media ${device.tablet} {
    padding: 30px 30px 260px 30px;
  }
  @media ${device.desktop} {
    padding: 30px 30px 360px 30px;
  }
`;


const VideoPlayer = styled.video` {


  max-width: 1200px;
  max-height: 680px;
  width: 100%;
  height: 100%;
  height: auto;
  margin: 0 auto;
  display: block;

}
`;

export {PlayerWrapper, VideoPlayer};
