import styled from 'styled-components';
import { device } from 'styles/mediaQueries.js';



const ScrollBarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateX(0);
  height: 5px;
  padding: 0px;
  margin: 0px;
  background: ${props => props.theme.rsaGradient};
  z-index: 9999;
  transition: transform 0.3s ease;
  @media ${device.tablet} {
    width: calc(100% - ${props => props.theme.menuWidth});
    transform: translateX(${props => props.theme.menuWidth});
  }
  &:before {
    content: "";
    right: 0;
    top: 0;
    background-color: #fff;
    height: 100%;
    width: ${props => props.width}%;
    position: absolute;
  }

`;



export {ScrollBarContainer};
