import styled from 'styled-components';
import { device } from 'styles/mediaQueries.js';
import { styles } from 'styles/sharedStyles.js';

const GRID_HEIGHT_D = 200;
const GRID_HEIGHT_M = 150;

const Grid = styled.div`
  @media ${device.mobileL} {
    display: grid;
    grid-template-columns: repeat(${styles.ideasGrid.maxColumns}, 1fr);
    grid-template-rows: repeat(${styles.ideasGrid.maxRows}, ${GRID_HEIGHT_D}px);
    grid-gap: 20px;
  }
`;

const GridCell = styled.div`
  height: ${GRID_HEIGHT_M}px;
  margin: 20px auto;
  @media ${device.mobileL} {
    height: ${GRID_HEIGHT_D}px;
    margin: 0;
    grid-column: span ${styles.ideasGrid.maxColumns};
  }
`;

const FitwardsCell = styled(GridCell)`
  @media ${device.mobileL} {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;
const LottotweeCell = styled(GridCell)`
  @media ${device.mobileL} {
    grid-column-start: 3;
    grid-column-end: 5;
  }
`;

Grid.GridCell = GridCell;
Grid.LottotweeCell = LottotweeCell;
Grid.FitwardsCell = FitwardsCell;

export default Grid;
