import React, { Component } from 'react';
import {
  HeroContainer,
  HeroCover,
  HeroCoverContent,
  ScreenWrapper,
  TitleSection
} from 'styles/shared/elementsStyles.js';

import Container from 'styles/shared/Container.js';
import { HeroButton } from 'styles/shared/Buttons.js';

import {
  ExperienceRow,
  Dates,
  Description,
  TechSkills,
  SkillGroup
} from './CvStyle.js';
import scrollAtBottom from 'components/hocs/scrollAtBottom/ScrollAtBottom';
import heroBig from 'img/cv-hero.jpg';


class Cv extends Component {
  render() {
    const cvURL = `${process.env.PUBLIC_URL}/rafael-salgado-almazan-cv.pdf`;
    return (
      <ScreenWrapper>
        <HeroContainer noPadding>
          <HeroCover bgImgBig={heroBig} darked>
            <Container transparent>
              <HeroCoverContent>
                <h1>Mi CV</h1>
                <HeroButton iconCV href={cvURL} target="_blank">Descargar CV</HeroButton>
              </HeroCoverContent>
            </Container>
          </HeroCover>
        </HeroContainer>
        <Container>
          <p>
            Desarrollador frontend con 15 años de experiencia en distintos proyectos web. Sobre todo eCommerce de venta de productos de lotería online.  Apasionado en el HTML, CSS y desarrollo en JavaScript, sobre todo en ReactJS.
          </p>
          <p>
          Siempre en equipos multidisciplinares usando metodologías agiles. Muy cercano al producto con experincia en UX/Design y prototipado.
          </p>
          <p>
          Hasta ahora toda mi carrera profesional la he desarrollado en empresas del grupo internacional ZEAL especializado en productos de lotería digitales.
          </p>
          <TitleSection category="second">
            <span>Experiencia</span>
          </TitleSection>
          <ExperienceRow>
            <Dates>
              <span>ENERO 2019 - JUNIO 2019</span>
              Pivoting Ventura24
            </Dates>
            <Description>
              <p>Cierre del eCommerce Ventura24:</p>
              <ul>
                <li>Creación de un site de resutados de lotería con Wordpress</li>
                <li>Implementación de un plugin que consume los servicios rest ya existentes. </li>
                <li>Optimización del site para SEO.</li>
                <li>Creación de un backoffice para gestionar usarios.  AngularJS,  Bootstrap, restFul Api.</li>
              </ul>
            </Description>
          </ExperienceRow>
          <ExperienceRow>
            <Dates>
                <span>2016 - 2019</span>
                Proyectos Noruega, Holanda<br/>
                ReactJS
            </Dates>
            <Description>
              <p>Unicef Lotteriet: eCommerce de Lotería para Unicef Noruega.<br/>
                 Raffld: eCommerce de Lotería en Holanda.</p>
                <ul>
                  <li>Implementación frontend de una SPA con ReactJs y MobX para la gestión del estado</li>
                  <li>Uso de CSS Modules y SASS para crear una UI responsive</li>
                  <li>Webpack, ES6, Babel, npm</li>
                  <li>Test unitarios con Mocha/Chai y Enzyme</li>
                  <li>Implementación de Google Tag Manager y Google Analytics en una SPA</li>
                  <li>Uso de Contentful como CMS</li>
                  <li>Internacionalización con i18next</li>
                  <li>Uso de Wordpress para landings y seo</li>
                  <li>Metodología Scrum</li>
                  <li>Github, PRs </li>
                </ul>
            </Description>
          </ExperienceRow>
          <ExperienceRow>
            <Dates>
                <span>2015 - 2016</span>
                  Product discovery, UX, prototipado
            </Dates>
            <Description>
              <p>Equipo para crear nuevos productos digitales:</p>
                <ul>
                  <li>Product discovery para nuevos productos de Lotería en Alemanía, Noruega y Holanda</li>
                  <li>Implementación de prototipos en HTML + Javascript y Axure</li>
                  <li>Validación con test de usuarios, fake door tests.</li>
                </ul>
            </Description>
          </ExperienceRow>
          <ExperienceRow>
            <Dates>
                <span>2003 - 2015</span>
                  Development, Diseño UX, protipado
            </Dates>
            <Description>
              <p>eCommerce Ventura24:</p>
                <ul>
                  <li>Creación, mantenimiento y desarrollo de nuevas funcionalidades en el frontend</li>
                  <li>HTML, CSS y JavaScript</li>
                  <li>Spring Boot, Thymeleaf, Maven</li>
                  <li>Preprocesado CSS con SASS</li>
                  <li>Empaquetado con Grunt</li>
                  <li>RequireJs como gestor de dependencias y carga de módulos AMD</li>
                  <li>jQuery para la interacción de usuario</li>
                  <li>Github, PRs </li>
                  <li>Maquetación HTML de newsletters e emails del sistema</li>
                  <li>Implementación y uso de Google Tag Manager y Google Analytics</li>
                  <li>Optimización con aproximación LEAN mediante tests A/B, user testing, focus groups</li>
                  <li>Diseño UX y creación de prototipos</li>
                  <li>Metodología Scrum, Kanban</li>
                  <li>Responsable disciplinar del equipo de frontend</li>
                </ul>
            </Description>
          </ExperienceRow>
            <TitleSection category="second"><span>Habilidades técnicas</span></TitleSection>
          <TechSkills>
            <SkillGroup>
              <div>HTML</div>
              <ul>
                <li>Maquetación HTML</li>
                <li>Motor de plantillas Jade</li>
              </ul>
            </SkillGroup>
            <SkillGroup>
              <div>CSS</div>
              <ul>
                  <li>CSS3</li>
                  <li>Preprocesador SASS</li>
                  <li>CSS Modules</li>
                  <li>Styled Components</li>
              </ul>
            </SkillGroup>
            <SkillGroup>
              <div>JavaScript</div>
              <ul>
                  <li>Vanilla JavaScript</li>
                  <li>jQuery</li>
                  <li>React, ReactNative</li>
                  <li>Redux, MobX</li>
                  <li>AngularJs</li>
              </ul>
            </SkillGroup>
            <SkillGroup>
              <div>Control de versiones</div>
              <ul>
                  <li>Git</li>
                  <li>Svn</li>
              </ul>
            </SkillGroup>
            <SkillGroup>
              <div>Otras</div>
              <ul>
                  <li>Wordpress</li>
                  <li>SEO</li>
                  <li>Prototipado en Axure</li>
                  <li>Adobe Creative Suite:
                      <ul>
                        <li>Photoshop</li>
                        <li>Premiere</li>
                        <li>After Effects</li>
                        <li>Ilustrator</li>
                      </ul>
                  </li>
              </ul>
            </SkillGroup>
            <SkillGroup>
              <div>Third Party Tools</div>
              <ul>
                  <li>Google Tag Manager</li>
                  <li>Google Analytics</li>
                  <li>Contentful</li>
                  <li>Unbounce</li>
                  <li>SalesManago</li>
                  <li>Hotjar, MouseFlow</li>
                  <li>Zapier</li>
                  <li>TypeForm</li>
              </ul>
            </SkillGroup>
          </TechSkills>
          <TitleSection twoLines category="second"><span>Formación complementaría</span></TitleSection>
          <p>2019. Curso <strong>JavaScript para profesionales</strong>. Impartido por RedRadix.</p>
          <p>Asistencia a Meetups y conferencias relacionadas con tecnologías web siempre que puedo. CodeMotion,  Commit Conf...</p>
          <p>2015. Formación para obtener <strong>Certified ScrumMaster</strong> de Scrum Alliance.</p>
          <p>2014. Curso Intensivo de verano <strong>Resoponsive Web development</strong> en Escuela Trazos.</p>
          <p>2002. <strong>Ingeniería Superior de Informática</strong> en la Universidad Complutense</p>

          <TitleSection category="second"><span>Idiomas</span></TitleSection>
          <p>
            <strong>Castellano:</strong><br/> Nativo
          </p>
          <p>
            <strong>Ingles:</strong><br/> Fluido escrito y hablado
          </p>
          </Container>
      </ScreenWrapper>
    );
  }
}

export default scrollAtBottom(Cv);
