import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import ProjectGrid from 'components/projectGrid/ProjectGrid';
import IdeasGrid from 'components/ideasGrid/IdeasGrid';
import {
  HeroContainer,
  HeroCover,
  HeroCoverContent,
  TitleSection
} from 'styles/shared/elementsStyles.js';

import { VideoGameBoy } from './ProjectsStyle.js';
import Container from 'styles/shared/Container.js';
import heroBig from 'img/projects/projects-hero-typewriter.jpg';
import gameBoy from 'img/projects/gameboy.mp4';

class Projects extends Component {
  constructor() {
    super();
    this.myVideoRef = React.createRef();
  }
  componentDidMount() {

    // Start gameboy video at specific time
    if(this.myVideoRef.current) {
      this.myVideoRef.current.addEventListener("loadedmetadata", function() {
        this.currentTime = 16;
      }, false);
    }
  }
  render() {

    return (
        <div>
        <HeroContainer noPadding>
          <HeroCover bgImgBig={heroBig}>
            <MediaQuery minWidth={1115}>
              <VideoGameBoy>
                <video autoPlay muted loop ref={this.myVideoRef}>
                  <source src={gameBoy} type="video/mp4" />
                </video>
              </VideoGameBoy>
            </MediaQuery>
            <Container transparent>
              <HeroCoverContent>
                <h1>Proyectos</h1>
              </HeroCoverContent>
            </Container>
          </HeroCover>
        </HeroContainer>
        <Container projects>
              <div>
                <TitleSection><span>Proyectos</span></TitleSection>
                <p>
                  Estos son proyectos en los que he trabajado, a nivel profesional y personal.
                </p>
                <ProjectGrid />
                <TitleSection largeTopPadding><span>Ideas</span></TitleSection>
                <p>
                  Ideas y conceptos que no vieron la luz pero que merece la pena no olvidar. Surgieron en concursos de ideas y etapas de product discovery. Iré añadiendo más según vaya encontrando el material.
                </p>
                <IdeasGrid />
              </div>
          </Container>
          </div>
    );
  }
}

export default Projects;
