import styled from 'styled-components';

const iconSizeWidth = 12;

const BackButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: middle;
  svg {
    width: ${iconSizeWidth}px;
    margin-right: ${props => props.theme.smallMargin};
    transition: margin 0.2s ease;
  }
`;


const BackButtonContainer = styled.div`
    display:  ${props => props.isMenuOpened ? 'none' : 'inline-block'};
    overflow: visible;
    margin: ${props => `25px ${props.theme.largeMargin} ${props.theme.largeMargin} ${props.theme.largeMargin}`};
    padding: 0;
    cursor: pointer;
    text-transform: uppercase;
    color: ${props => props.shouldBeWhite ? props.theme.hamburgerColorWithBg : props.theme.hamburgerColor};
    border: 0;
    background-color: transparent;
    font-size: 0.8em;
    position:  absolute;
    right: 0;
    top: 0;
    z-index: 10001;
    transition: transform 0.3s ease;
    padding-top: 3px;

    border: 1px solid ${props => props.shouldBeWhite ? props.theme.hamburgerColorWithBg : props.theme.hamburgerColor};
    border-radius: 5px;
    padding: ${props => `${props.theme.smallMargin} ${props.theme.mediumMargin}`};

    ${BackButtonWrapper} {
      svg path {
        fill: ${props => props.shouldBeWhite ? props.theme.hamburgerColorWithBg : props.theme.hamburgerColor};
      }
    }

    &:hover {
      ${BackButtonWrapper} {
        svg {
          margin-right: 10px;
        }
      }
    }
`;



export {BackButtonContainer, BackButtonWrapper};
