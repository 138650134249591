import styled from 'styled-components';
import { device } from 'styles/mediaQueries.js';

const EquiaVars = {
  laboral: {
    color: '#2d7705',
  },
  fiscal:{
    color:'#63c0e3'
  },
  contable: {
    color: '#e1c902',
  },
  juridico: {
    color: '#7ba200',
  },
  iconSizeBig: 180,
  iconSizeSmall: 115
}

const EquiaIcons = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const EquiaIcon = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.smallPadding} 0;
  margin: ${props => props.theme.smallMargin};
  border-bottom: 4px solid ${props => EquiaVars[props.area].color};

  & > img {
    width: ${EquiaVars.iconSizeSmall}px;
    @media ${device.tablet} {
      width: ${EquiaVars.iconSizeBig}px;
    }
    margin: 0 auto;
  }

  & > span {
    color: ${props => EquiaVars[props.area].color};
    text-align: center;

    font-size: .90em;
  }

  }

`;

export { EquiaIcons, EquiaIcon };
