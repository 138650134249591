import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {PlayerWrapper, VideoPlayer} from './VideoPlayerDesktopStyle.js';

class VideoPlayerDesktop extends Component {

  render() {
    const {
      video
    } = this.props;


  return (
    <PlayerWrapper>
      <VideoPlayer autoPlay muted loop>
        <source src={video} type="video/mp4" />
      </VideoPlayer>
    </PlayerWrapper>
  );
  }
}


VideoPlayerDesktop.propTypes = {
  video: PropTypes.string.isRequired
};
VideoPlayerDesktop.defaultProps = {
  children: ""
};


export default VideoPlayerDesktop;
