import React, { Component } from 'react';
import {
  HeroContainer,
  HeroCover,
  HeroCoverContent,
  ScreenWrapper
} from 'styles/shared/elementsStyles.js';

import Container from 'styles/shared/Container';

import {
  MapIndicator,
  ShootingStar,
  FormContainer,
  ContactFormWrapper,
  EmailSentOk
} from './ContactStyle.js';
import { ContactFormButton, Spinner } from 'styles/shared/Buttons';
import InputField from 'components/inputField/InputField';
import TextAreaField from 'components/textAreaField/TextAreaField';
import heroBig from 'img/contact-hero.jpg';
import { ReactComponent as OkIcon } from 'img/ok-icon.svg';
import * as emailjs from 'emailjs-com';

const MAX_STARS = 6;

class Contact extends Component {
  constructor() {
    super();
    this.state = {
        name: '',
        email: '',
        textMail: '',
        nameError: '',
        emailError: '',
        textMailError: '',
        sending: false,
        formEmailSent: false
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeField = this.onChangeField.bind(this);
  }
  randFloat(min, max) {
    return (Math.random() * (max - min) + min)
  }

  getStars() {
    return Array.from({length: MAX_STARS}, (v, i) => {
      const top = this.randFloat(0, 100);
      const duration = this.randFloat(2, 15);
      const delay = (i === 0) ? 0 : this.randFloat(0, 15);
      return <ShootingStar
        initTop={top}
        duration={duration}
        delay={delay}
        key={i}
      />;
    })

  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  onChangeField(e){
    const {name, value} = e.target;
    this.setState({
      [name]:value,
      [`${name}Error`]: '',
    })
  }
  validForm() {
    const { name, email, textMail } = this.state;

    let res = true;
    // Name
    if (name === '') {
      res = false;
      this.setState({
        nameError: 'El nombre es obligatorio',
      })
    }

    if (email === '') {
      res = false;
      this.setState({
        emailError: 'El email es obligatorio',
      })
    }

    if (!this.validateEmail(email)) {
      res = false;
      this.setState({
        emailError: 'El email no es válido',
      })
    }

    if (textMail === '') {
      res = false;
      this.setState({
        textMailError: 'El textp es obligatorio',
      })
    }

    return res;
  }

  handleSubmit(e) {
    e.preventDefault();
    if (!this.validForm()) return;
    var template_params = {
       from_name: this.state.name,
       reply_to: this.state.email,
       text_mail: this.state.textMail
    }

    var service_id = "default_service";
    var template_id = "template_KHMBGHlb";

    // Spin the button
    this.setState({
      sending: true,
    })

    emailjs.send(service_id, template_id, template_params)
          .then(res => {
            this.setState({ formEmailSent: true })
          })
          // Handle errors here however you like, or use a React error boundary
    .catch(err => console.error('Failed to send feedback. Error: ', err));
  }

  componentDidMount() {
     emailjs.init("user_Mog18LSmpJSewfOepP3FJ");
  }

  renderForm() {
    const buttonItem = this.state.sending ? (<Spinner />) : (<span>ENVIAR</span>);
    return (
      <div>
        <h2>Escríbeme</h2>
        <form>
          <InputField
            label="Nombre"
            name="name"
            id="nameForm"
            value={this.state.name}
            error={this.state.nameError}
            onChange={this.onChangeField}
          />
          <InputField
            label="Email"
            name="email"
            id="emailForm"
            type="email"
            value={this.state.email}
            error={this.state.emailError}
            onChange={this.onChangeField}
          />
          <TextAreaField
            label="Cuéntame algo..."
            name="textMail"
            id="textEmailForm"
            error={this.state.textMailError}
            onChange={this.onChangeField}
          >
            {this.state.text}
          </TextAreaField>
          <ContactFormButton onClick={this.handleSubmit}>
            {buttonItem}
          </ContactFormButton>
        </form>
      </div>
    );
  }

  renderEmailSent() {
    return (
      <EmailSentOk>
        <OkIcon />
        Tu email se ha enviado con éxito.<br/>¡¡Muchas Gracias!!
      </EmailSentOk>
    );
  }

  render() {
    const emailSection = this.state.formEmailSent ? this.renderEmailSent() : this.renderForm();
    return (
      <div>
      <ScreenWrapper>
        <HeroContainer noPadding>
          {this.getStars()}
          <HeroCover bgImgBig={heroBig} >
          <MapIndicator>
            Psst!!<br/>Estoy aquí
          </MapIndicator>
            <Container transparent>
              <HeroCoverContent>
                <h1>Contacto</h1>
              </HeroCoverContent>
            </Container>
          </HeroCover>
        </HeroContainer>
        <Container>
          <p>
            Por alguna extraña razón has llegado a está sección,
            ¿significa eso que quieres contactar conmigo?, ¿quién sabe? Yo por si acaso dejo aquí abajo un formulario de contacto.
          </p>
        </Container>

      </ScreenWrapper>
      <ContactFormWrapper>
      <FormContainer>
        {emailSection}
      </FormContainer>
      </ContactFormWrapper>
      </div>
    );
  }
}

export default Contact;
