import React, { Component } from 'react';
import ProjectCard from 'components/projectCard/ProjectCard';
import Grid from './ProjectGridStyle.js';
import { Paths } from 'routes/RootRoutes';
import v24ImageBg from 'img/projects/v24/v24-cardBg.jpg';
import v24ImageTitle from 'img/projects/v24/cardTitle-v24.png';
import raffldImageBg from 'img/projects/raffld/raffld-cardBg.jpg';
import raffldImageTitle from 'img/projects/raffld/raffld-logo.svg';
import unnoImageBg from 'img/projects/unno/unno-cardBg.jpg';
import unnoImageTitle from 'img/projects/unno/unno-logo.svg';
import tgaImageBg from 'img/projects/tga/tga-cardBg.jpg';
import tgaImageTitle from 'img/projects/tga/tga-logo.svg';
import equiaImageBg from 'img/projects/equia/equia-cardBg.jpg';
import equiaImageTitle from 'img/projects/equia/equia-logo.svg';
import rsaImageBg from 'img/projects/rsa/rsa-hero-mobile.jpg';
import rsaImageTitle from 'img/projects/rsa/rsa-log-bw.svg';


class ProjectGrid extends Component {

  render() {

  return (
    <Grid>
      <Grid.RaffldCell>
        <ProjectCard
          name="Raffld"
          description="Fuck Money, Create Memories"
          redirectTo={Paths.projects.raffld}
          bgImage={raffldImageBg}
          topImage={raffldImageTitle}
          iconScale={.5}
        />
      </Grid.RaffldCell>
      <Grid.UnnoCell>
        <ProjectCard
          name="Unicef Lotteriet"
          description="Lotería de Unicef en Noruega"
          redirectTo={Paths.projects.unno}
          bgImage={unnoImageBg}
          topImage={unnoImageTitle}
          iconScale={1.3}
        />
      </Grid.UnnoCell>
      <Grid.V24Cell>
        <ProjectCard
          name="Ventura24"
          description="Número 1 en venta de lotería online"
          redirectTo={Paths.projects.v24}
          bgImage={v24ImageBg}
          topImage={v24ImageTitle}
          iconScale={.8}
        />
      </Grid.V24Cell>
      <Grid.TgaCell>
        <ProjectCard
          name="Tomás García Asensio"
          description="Web oficial del pintor constructivista"
          redirectTo={Paths.projects.tga}
          bgImage={tgaImageBg}
          topImage={tgaImageTitle}
          iconScale={.3}
        />
      </Grid.TgaCell>
      <Grid.EquiaCell>
        <ProjectCard
          name="Asesoría Equia"
          description="Asesoría Integral de Empresas"
          redirectTo={Paths.projects.equia}
          bgImage={equiaImageBg}
          topImage={equiaImageTitle}
          iconScale={.5}
        />
      </Grid.EquiaCell>
      <Grid.RsaCell>
        <ProjectCard
          name="RSA"
          description="Mi página web, claro que si"
          redirectTo={Paths.projects.rsa}
          bgImage={rsaImageBg}
          topImage={rsaImageTitle}
          iconScale={.7}
        />
      </Grid.RsaCell>
    </Grid>
  );
  }
}

export default ProjectGrid;
