import React, { Component } from 'react';
import Container from 'styles/shared/Container.js';
import withScrollProgressBar from 'components/hocs/withScrollProgressBar/WithScrollProgressBar';
import scrollAtBottom from 'components/hocs/scrollAtBottom/ScrollAtBottom';
import {
  HeroContainer,
  HeroCover,
  ScreenWrapper,
  ProjectLogo,
  TechContent,
  TechContentRow,
  TechDescription,
  TechSeparator,
  ScreenshotWrapper,
  MainQuote,
  Columns,
  Column,
  ResponsiveImage
  } from 'styles/shared/elementsStyles.js';

  import {
    Passport,
    RaffldIcons
  } from './RaffldStyle.js';

import heroBig from 'img/projects/raffld/raffld-hero-image.jpg';
import bottomHero from 'img/projects/raffld/Adventure.jpg';
import heroSmall from 'img/projects/raffld/raffld-hero-image-small.jpg';
import logo from 'img/projects/raffld/raffld-logo-color.svg';
import raffldPassport from 'img/projects/raffld/Raffld-Passport.jpg';
import raffldTablet from 'img/projects/raffld/raffld-tablet.png';
import contentful from 'img/projects/raffld/contentful.jpg';
import iconAdventure from 'img/projects/raffld/raffld-adventure.svg';
import iconCityTrips from 'img/projects/raffld/raffld-citytrips.svg';
import iconEntertaiment from 'img/projects/raffld/raffld-entertainment.svg';
import iconRelaxation from 'img/projects/raffld/raffld-relaxation.svg';
import screenshot1 from 'img/projects/raffld/raffld-desktop1.jpg';
import screenshot2 from 'img/projects/raffld/raffld-desktop2.jpg';


class Raffld extends Component {

  render() {
    return (
      <ScreenWrapper>
        <HeroContainer noPadding>
          <HeroCover bgImgBig={heroBig} bgImgSmall={heroSmall}/>
          <ProjectLogo logoHeight="120px">
            <img src={logo} alt="Raffld" />
          </ProjectLogo>
          <span>Fuck Money, Make Memories</span>
        </HeroContainer>
        <Container>
          <div>
            <p>
              Conseguimos una licencia para crear y operar una nueva lotería en Holanda. La licencia obligaba a que la lotería destinará una parte a fines sociales. Después de una fase de product discovery en la que también estuve involucrado nació <strong>Raffld</strong>.
            </p>
            <MainQuote>
              <p>Raffld fue la primera lotería donde los premios eran experiencias y no premios en metálico. <strong>Fuck Money, Make Memories</strong> era el claim. Una lotería claramente orientada a un target Millenial.</p>
              <p>Se podía jugar en grupo y los premios eran viajes exóticos poco convencionales.</p>
            </MainQuote>
            <RaffldIcons>
              <img src={iconAdventure} alt="Adventure" />
              <img src={iconCityTrips} alt="City Trips" />
              <img src={iconEntertaiment} alt="Entertaimnent" />
              <img src={iconRelaxation} alt="Relaxation" />
            </RaffldIcons>
            <p>Hablo en pasado porque lamentablemente el producto no llego a triunfar, quizá era un producto dificil de explicar, el caso es que los números no acompañaron y hubo que discontinuar el producto.</p>
          </div>
        </Container>
        <TechContentRow>
          <TechContent>
          <ul>
            <li>Java</li>
            <li>Spring Boot</li>
            <li>RestFul Api</li>
            <li>OAuth 2</li>
            <li>React</li>
            <li>Webpack</li>
            <li>Contentful</li>
            <li>SASS</li>
            <li>Css Modules</li>
            <li>Wordpress</li>
          </ul>
          </TechContent>
          <TechSeparator />
          <TechDescription>
            Se creó un backend basado en microservicios en Java con Spring Boot y una API REST para poder nutrir al frontend.<br/>
            El frontend constaba de 2 partes:
            <ul>
              <li>Una single page application implementada con <strong>React</strong> para la parte del juego, cuenta de usuario (login, registro, area privada...) y el proceso de compra.</li>
              <li>Un <strong>Wordpress</strong> para la landing principal y para crear las páginas corporativas (política de cookies, normas de usuario, artículos). Se creó un plugin para poder reutilizar componentes creados en el SPA.</li>
            </ul>
            <p>
              Desarrollado con metodología <strong>Scrum</strong> con un equipo distribuido en varias localizaciones. El Product Owner, Scrum Master y parte del equipo de desarrollo estaban en <strong>Londres</strong>, la otra parte estabamos en <strong>Madrid</strong> y el equipo de QA en Ucrania.
            </p>
            <p>
              Los sprints eran de dos semanas y todas las reuniones eran por Hangouts.
            </p>
          </TechDescription>
        </TechContentRow>
        <Container>
          <Columns>
            <Column>
              <h2>¿Cómo funcionaba?</h2>
              <p>
                Se trataba de un producto de lotería poco convencional. No había premios en metálicos sino viajes de experiencias.
              </p>

              <p>
                A la hora de comprar un ticket hay que elegir una categoría de viaje y una ONG. Hay cuatro categorías de viajes <strong>Aventuras, Ciudades, Entretenimiento y Relajación</strong> que determinaban el tipo de viaje que se llevaría el ganador.
                Una parte del dinero de tu ticket iba destinado a la ONG que hubieras elegido.
              </p>
              <p>
                Los viernes era día de sorteo, era una rifa, de todos los tickets vendidos se extraía un ganador que se llevaba el viaje de la categoría que hubiese elegido. Los viajes iban cambiando cada semana y eran del tipo "Volar en globo en la Capadocia", "Festival de Heavy Metal en Hamburgo", "Avistar ballenas en Las Canarias".
              </p>
            </Column>
            <Column>
              <Passport src={raffldPassport} />
            </Column>
            </Columns>
        </Container>
        <ScreenshotWrapper withBgColor>
          <div><img src={screenshot1} alt="Euromillones en Ventura24" /></div>
          <div><img src={screenshot2} alt="Premios repartidos" /></div>
        </ScreenshotWrapper>
        <Container>
          <h2>Jugar en grupo</h2>
          <p>
            Al estar orientado a un target más joven, se introdujo lo opción de poder jugar en grupo. Al comprar un ticket, el usuario creaba un grupo al cual podía invitar a amigos y familiares, si alguno de estos compraba otro ticket, las opciones de ganar aumentaban.
          </p>
          <ResponsiveImage src={raffldTablet} />
        </Container>

        <Container>
          <div>
          <h2>CMS: Contentful</h2>
          <p>
            Debido a que cada semana había que introducir premios nuevos, con sus imágenes, títulos, decscripciones y demás información adicional decidimos utilizar un cms externo para ayudarnos con está tarea.
          </p>
          <p>
          Al estar creando una SPA que tiraba de un API RESTful, nos decidimos por <strong>Contenful</strong>. Contenful es un CMS que te permite crear tu propio modelo de datos y luego y nutriéndolo modificando con contenido a través de un amigable interfaz.
          Después este contenido se distribuye mediante un API REST que puedes usar donde quieras, una APP, una web, etc... con el diseño que a ti más te convenga.
          </p>
          <ResponsiveImage src={contentful} alt="Contentful" />
          </div>
        </Container>
        <HeroContainer noPadding>
          <HeroCover bgImgBig={bottomHero}/>
        </HeroContainer>
      </ScreenWrapper>
    );
  }
}

export default scrollAtBottom(withScrollProgressBar(Raffld));
