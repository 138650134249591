import React, { Component } from 'react';
import ScrollBar from 'components/scrollBar/ScrollBar';

export default function withScrollProgressBar(WrappedComponent) {
  return class extends Component {
    render() {
      return (
        <div>
          <ScrollBar/>
          <WrappedComponent />
        </div>
      );
    }
  }
}
