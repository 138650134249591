import styled from 'styled-components';
import { device } from 'styles/mediaQueries.js';

const Combination = styled.img`
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
  @media ${device.desktop} {
    height: 427px;
    max-width: none;
  }
`;

const RaffldIcons = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  & > img {
    width: 60px;
    height: 60px;
    @media ${device.tablet} {
      width: 80px;
      height: 80px;
    }
  }

`;


export { Combination, RaffldIcons };
